import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgott-password',
  templateUrl: './forgott-password.component.html',
  styleUrls: ['./forgott-password.component.scss']
})
export class ForgottPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
