import { Component, OnInit } from '@angular/core';
import {ArticleVM} from "../../../model/viewModel/article.vm";
import {ArticleService} from "../../../service/article.service";
import {ActivatedRoute, Router} from "@angular/router";
import {delay, switchMap} from "rxjs";

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  data: ArticleVM | undefined = undefined;
  constructor(
    private articleService: ArticleService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(switchMap(params => {
      return this.articleService.getArticleById(params["id"])
    })).subscribe(article => {
      this.data = article;
    });
  }

}
