import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CalculatorService} from "../../../../service/calculator.service";
import {CalculatorCourseItemVM} from "../../../../model/viewModel/calculator.vm";

export type Event = { type: "next", model: Model } | { type: "back" };
export type Model = CalculatorCourseItemVM;

type University = {
  id: number;
  name: string;
};

type CourseGroup = {
  name: string;
  universities: {
    id: number;
    name: string;
  }[];
}

@Component({
  selector: 'app-calculator-card-course-selector',
  templateUrl: './calculator-card-course-selector.component.html',
  styleUrls: ['./calculator-card-course-selector.component.scss']
})
export class CalculatorCardCourseSelectorComponent implements OnInit {

  isLoading: boolean;
  private rawCalculatorData: CalculatorCourseItemVM[];
  courses: CourseGroup[] = [];
  universities: University[] = [];
  @Input() isInActiveState: boolean;
  @Output() output = new EventEmitter<Event>();
  private model: Model = undefined;
  private selectedCourseGroup: CourseGroup

  constructor(
    private calculatorService: CalculatorService,
  ) {
    this.calculatorService.getCalculatorData()
      .subscribe(data => {
        this.rawCalculatorData = data;
        let mapperObject = {};
        data.forEach(item => {
          let universityArray = mapperObject[item.name] || [];
          universityArray.push(item.university);
          mapperObject[item.name] = universityArray;
        });
        this.courses = Object.keys(mapperObject).map(key => {
          return <CourseGroup>{
            name: key,
            universities: mapperObject[key]
          };
        });
        console.log(mapperObject);
      });
  }

  ngOnInit(): void {
  }

  onCourseChanged($event) {
    let groupIndex = $event.target.value;
    if (groupIndex < 0 || groupIndex >= this.courses.length) {
      this.universities = [];
    } else {
      this.selectedCourseGroup = this.courses[groupIndex];
      this.universities = this.selectedCourseGroup.universities;
    }
    this.model = undefined;
  }

  onUniversityChanged($event) {
    let universityId = $event.target.value;
    let targetEntry = this.rawCalculatorData.find(item => item.name == this.selectedCourseGroup.name && item.university.id == universityId);
    console.log("Calculated target entry: ", targetEntry);
    this.model = targetEntry;
  }

  onNextButtonClicked() {
    this.output.emit({type: 'next', model: this.model});
  }

  isValid(): boolean {
    return !!this.model
  }

}
