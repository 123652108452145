<div class="card user-transaction-container container mt-4">
  <div class="card-body">
    <h3 class="card-title mb-4">{{ "Purchase history" | trans: 'userprofile.purchase_history_header' }}</h3>
    <div class="transaction-container" *ngIf="state == 'loaded' && transactions.length != 0">
      <div
        class="transaction-item p-4"
        *ngFor="let transaction of transactions"
        [class.service]="transaction.type == 'service'"
        [class.plan]="transaction.type == 'plan'"
      >
        <h5 class="mb-0 pb-0">{{ transaction.name }}</h5>
        <small class="mb-2 d-block"
               *ngIf="transaction.type == 'service'">{{ "Service" | trans: 'userprofile.purchase_history_item_type_service' }}</small>
        <small class="mb-2 d-block"
               *ngIf="transaction.type == 'plan'">{{ "Plan" | trans: 'userprofile.purchase_history_item_type_plan' }}</small>
        <div class="price fs-4">{{ transaction.price }} HUF</div>
        <div class="row">
          <div class="col-6">
            <small *ngIf="transaction.state == 'created'">
              <span class="fw-bold">{{ "Status" | trans: 'userprofile.purchase_history_item_status_title' }}:</span>
              {{ "Created" }}}
            </small>
            <small *ngIf="transaction.state == 'pending'">
              <span class="fw-bold">{{ "Status" | trans: 'userprofile.purchase_history_item_status_title' }}:</span>
              {{ "Pending" | trans: 'userprofile.purchase_history_item_status_label_pending' }}
            </small>
            <small *ngIf="transaction.state == 'error'">
              <span class="fw-bold">{{ "Status" | trans: 'userprofile.purchase_history_item_status_title' }}:</span>
              {{ "Failed" | trans: 'userprofile.purchase_history_item_status_label_failed' }}
            </small>
            <small *ngIf="transaction.state == 'success'">
              <span class="fw-bold">{{ "Status" | trans: 'userprofile.purchase_history_item_status_title' }}:</span>
              {{ "Success" | trans: 'userprofile.purchase_history_item_status_label_success' }}
            </small>
          </div>
          <div class="col-6 text-end">
            <small>{{ transaction.createdAt | date: 'yyyy/MM/dd' }}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="row empty-list" *ngIf="state == 'loaded' && transactions.length == 0">
      <img src="assets/ic_transaction.svg" class="text-center">
      <h4
        class="text-center mt-4 mb-4 d-block">{{ "No purchase recorded" | trans: 'userprofile.purchase_history_empty' }}</h4>
    </div>

    <div class="row error-list" *ngIf="state == 'error'">
      <img src="assets/ic_error_emoji.svg">
      <h4
        class="text-center mt-4">{{ "Error during the transaction loading" | trans: 'userprofile.purchase_history_error_title' }}</h4>
      <button
        class="btn btn-outline-secondary mb-4"
        (click)="onRetryButtonClicked()">
        {{ "Retry" | trans: 'userprofile.purchase_history_error_button' }}
      </button>
    </div>

    <div class="row loading p-4" *ngIf="state == 'loading'">
      <app-loading></app-loading>
    </div>
  </div>
</div>
