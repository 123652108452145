import {Component, OnInit} from '@angular/core';
import {ServiceVM} from "../../../model/viewModel/service.vm";
import {ServicesService} from "../../../service/services.service";
import {GlobalDataService} from "../../../service/global-data.service";
import {AuthService} from "../../../service/auth.service";
import {Router} from "@angular/router";
import {OrderService} from "../../../service/order.service";
import {PlansService} from "../../../service/plans.service";
import {PlanVM} from "../../../model/viewModel/plan.vm";
import {Observable, zip} from "rxjs";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  services?: ServiceVM[] = undefined;
  plans?: PlanVM[] = undefined;

  get isLoading() {
    return !this.services || !this.plans;
  }

  constructor(
    private servicesService: ServicesService,
    private plansServices: PlansService,
    private orderService: OrderService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    zip([
      this.servicesService.getServices(),
      this.plansServices.getPlans()
    ]).subscribe(
      response => {
        console.log("Response: ", response);
        this.services = response[0] as ServiceVM[];
        this.plans = response[1] as PlanVM[];
      }
    );
    // this.servicesService.getServices().subscribe(data => {
    //   console.log(data);
    //
    //   this.services = data;
    // });
  }

  onPurchaseServiceButtonClicked(service: ServiceVM) {
    this.purchase(service.id, "service");
  }

  onPurchasePlanButtonClicked(plan: PlanVM) {
    this.purchase(plan.id, "plan");
  }

  private purchase(id: number, type: "service" | "plan") {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(["login"]);
      return;
    }

    let $delegate: Observable<number> = null;
    if (type === "service") {
      $delegate = this.orderService.createServiceOrder(id);
    } else if (type === "plan") {
      $delegate = this.orderService.createPlanOrder(id);
    } else {
      throw new Error(`Invalid type argument provided: ${type}`);
    }
    $delegate
      .subscribe((transId) => {
        this.router.navigate(['payment', transId])
      });
  }


}
