import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {UserFileService} from "../../../../service/user.file.service";
import {DialogService} from "../../../../service/dialog.service";
import {mergeMap, retry} from "rxjs";
import {UserFileDTO} from "../../../../model/http/user.file.dto";
import {TranslationService} from "../../../../service/translation.service";

@Component({
  selector: 'app-user-profile-files',
  templateUrl: './user-profile-files.component.html',
  styleUrls: ['./user-profile-files.component.scss']
})
export class UserProfileFilesComponent implements OnInit {
  isLoading: boolean = false;
  files: UserFileDTO[];
  @ViewChild("fileInput")
  private fileInput: ElementRef<HTMLInputElement>;

  submitted: boolean = false;
  isFileFormLoading: boolean = false;
  uploadForm = new FormGroup({
    name: new FormControl("", [
      Validators.required
    ]),
    description: new FormControl("", [
      Validators.required
    ]),
    file: new FormControl(null, [
      Validators.required
    ])
  });

  get nameField(): AbstractControl {
    return this.uploadForm.get("name");
  }

  get descriptionField(): AbstractControl {
    return this.uploadForm.get("description");
  }

  get fileField(): AbstractControl {
    return this.uploadForm.get("file");
  }

  constructor(
    private userFileService: UserFileService,
    private dialogService: DialogService,
    private translationService: TranslationService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userFileService.getFiles()
      .pipe(retry({
        delay: 5000
      }))
      .subscribe(response => {
        this.isLoading = false;
        this.files = response.data;
      });
  }

  onFileChanged(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({file: file});
  }

  onUploadSubmit() {
    this.uploadForm.markAllAsTouched()
    this.submitted = true;
    if (!this.uploadForm.valid) {
      return;
    }
    this.isLoading = true;
    this.fileInput.nativeElement.value = null;
    let value = this.uploadForm.value;
    this.userFileService.uploadFile(value.name, value.description, value.file)
      .pipe(mergeMap(() => this.userFileService.getFiles()))
      .subscribe({
        next: response => {
          this.isLoading = false;
          this.files = response.data;
          this.uploadForm.reset();
          this.isFileFormLoading = false;
        },
        error: error => {
          this.isFileFormLoading = false;
          console.error(error);
          //TODO: Translation
          this.dialogService.show({
            title: this.translationService.trans("userprofile_userFiles.dialog_upload_error_title", "Error"),
            content: this.translationService.trans("userprofile_userFiles.dialog_upload_error_content", "Error happened during the file uploading, please try again later"),
            type: "error",
            buttons: {
              positive: {
                label: this.translationService.trans("userprofile_userFiles.dialog_upload_error_button_positive", "OK")
              }
            }
          });
        }
      });
  }

  onDeleteFileClicked(file: UserFileDTO) {
    this.dialogService.show({
      title: this.translationService.trans("userprofile_userFiles.dialog_delete_confirm_title", "Are you sure?"),
      content: this.translationService.trans("userprofile_userFiles.dialog_delete_confirm_content", "Do you wan to delete this file?"),
      type: "info",
      buttons: {
        positive: {
          label: this.translationService.trans("userprofile_userFiles.dialog_delete_confirm_button_positive", "Ok"),
          callback: () => {
            this.deleteFile(file);
          }
        },
        negative: {
          label: this.translationService.trans("userprofile_userFiles.dialog_delete_confirm_button_negative", "Cancel")
        }
      }
    });
    return false;
  }

  private deleteFile(file: UserFileDTO) {
    this.isLoading = true;
    this.userFileService.deleteFile(file.id)
      .pipe(mergeMap(() => this.userFileService.getFiles()))
      .subscribe(
        response => {
          this.isLoading = false;
          this.files = response.data;
        },
        error => {
          this.isLoading = false;
          console.error("Error during the delete file: ", error);
          this.dialogService.show({
            title: this.translationService.trans("userprofile_userFiles.dialog_delete_error_title", "Error"),
            content: this.translationService.trans("userprofile_userFiles.dialog_delete_error_content", "Error happened during the file deletion, please try again later"),
            type: "error",
            buttons: {
              positive: {
                label: this.translationService.trans("userprofile_userFiles.dialog_delete_error_button_positive", "Ok")
              }
            }
          });
        }
      );
  }
}
