import {Injectable} from '@angular/core';
import {map, Observable, tap, zip} from "rxjs";
import {LocaleDTO} from "../model/http/global.dto";
import {TranslationDTO} from "../model/http/translation.dto";
import {ResponseDTO} from "../model/http/base.dto";
import {ApiService, RequestBuilder} from "./http/api.service";
import {ContactEmailDTO} from "../model/http/contact.dto";

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  private _translations: TranslationDTO | null = null;
  private _locales: LocaleDTO[] = [];
  private _contact: ContactEmailDTO;

  constructor(
    private api: ApiService,
  ) {}

  get translations() {
    return this._translations!!;
  }

  get locales(): LocaleDTO[] {
    return this._locales;
  }

  get contactEmail() : string | null {
    return this._contact?.data?.contactEmail;
  }


  load(): Observable<void> {
    return zip(
      this.api.get<ContactEmailDTO>(RequestBuilder.create("/giraffe/comm/contact-email")),
      this.api.get<LocaleDTO[]>(RequestBuilder.create("/i18n/locales")),
      this.api.get<ResponseDTO<TranslationDTO>>(RequestBuilder.create("/transkey").locale())
    )
      .pipe(
        tap({
          next: (params) => {
            let [contactEmail, locale, translations] = params;
            this._contact = <ContactEmailDTO>contactEmail;
            this._locales = <any>locale;
            this._locales.sort((left, right) => right.id - left.id);
            this._translations = <any>translations.data.attributes;
          }
        }),
        map(() => {
          return null;
        })
      );
  }
}
