import {Component, OnInit} from '@angular/core';
import {BehaviourCourseTagDTO, CourseTagDTO} from "../../../model/http/test.dto";
import {PersonalityTestService} from "../../../service/personality-test.service";
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, retry, Subscription, switchMap, zip} from "rxjs";
import {TestCourseDTO, TestResultDTO, TestResultItemDTO} from "../../../model/http/test-result.dto";
import {ServicesService} from "../../../service/services.service";
import {ServiceVM} from "../../../model/viewModel/service.vm";
import {StorageService} from "../../../service/storage.service";
import {OrderService} from "../../../service/order.service";
import {MediaMapperService} from "../../../mapper/media-mapper.service";
import {ImageVM} from "../../../model/viewModel/base.vm";
import {MediaContainerDTO} from "../../../model/http/base.dto";

@Component({
  selector: 'app-personality-test-result',
  templateUrl: './personality-test-result.component.html',
  styleUrls: ['./personality-test-result.component.scss']
})
export class PersonalityTestResultComponent implements OnInit {
  isLoaded: boolean = false;
  data: TestResultDTO;
  targetService: ServiceVM | undefined;
  private testResultId: number;

  constructor(
    private personalityTestService: PersonalityTestService,
    private activatedRoute: ActivatedRoute,
    private servicesService: ServicesService,
    private orderService: OrderService,
    private router: Router,
    private storageService: StorageService,
    private mediaMapperService: MediaMapperService,
  ) {
  }
  private subscription: Subscription;

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(){
    if(this.subscription){
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    this.subscription = this.activatedRoute.params
      .pipe(
        switchMap(params => {
          this.testResultId = params["id"];
          console.log("Route params: ", params);
          return zip(
            [
              this.personalityTestService.getTestResult(params["id"]),
              this.servicesService.findTestUnlockService()
            ]
          )
        }),
        retry({
          delay: 5000
        })
      )
      .subscribe((data) => {
        let [personalityTestResponse, targetService] = data;
        this.data = <any>personalityTestResponse;
        this.targetService = <any>targetService;
        this.isLoaded = true;
      });
  }

  getPercentage(item: TestResultItemDTO) : number{
    if(item.max_points == 0){
      return 100;
    }
    return ((item.earned_points || 0) / (item.max_points || 1)) * 100;
  }


  onPurchaseButtonClicked() {
    if(!this.targetService){
      return;
    }
    this.isLoaded = false;
    this.orderService.createServiceOrder(this.targetService.id, this.testResultId).subscribe(response => {
      this.storageService.pendingTestResult = this.testResultId; //TODO: Add new field for this purpose, this can lead some nasty bugs
      console.log(`Pending test result: ${this.testResultId}`);
      this.router.navigate(["payment", response]);
    });
  }

  getCoverImage(image: MediaContainerDTO) : ImageVM {
    return this.mediaMapperService.selectImageFromContainer(image);
  }

  onAdditionalServicesClicked() {
    this.router.navigate(["services"]);
    return false;
  }

  onCourseDetailsClicked(course: TestCourseDTO) {
    this.router.navigate(["course", course.id]);
  }
}
