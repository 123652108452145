<div class="dialog-container" *ngIf="alertService.currentDialog">
  <div class="dialog">
    <div class="title">{{alertService.currentDialog?.title}}</div>
    <div class="content">{{alertService.currentDialog?.content}}</div>
    <div class="button-container">
      <div class="negative-button-container" >
       <button tabindex="1" [autofocus]="true" *ngIf="buttonNegative" (click)="onButtonClicked(buttonNegative)">{{buttonNegative.label}}</button>
      </div>
      <div class="positive-button-container">
        <button tabindex="2" [autofocus]="buttonNegative == null" *ngIf="buttonPositive" (click)="onButtonClicked(buttonPositive)">{{buttonPositive.label}}</button>
      </div>
    </div>
  </div>
</div>
