import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculator-card-info',
  templateUrl: './calculator-card-info.component.html',
  styleUrls: ['./calculator-card-info.component.scss']
})
export class CalculatorCardInfoComponent {

  constructor() { }

}
