<app-menu currentMenu="main"></app-menu>
<div class="page page-register">
    <div class="card-register">
        <form (ngSubmit)="onRegisterFormSubmitted()" [formGroup]="registerForm" *ngIf="state == 'register-init'">
            <img src="assets/menu_logo.svg" class="logo">
            <h1>{{"Sign up" | trans:'register.header'}}</h1>

            <div class="input-container">
                <label>{{"Name" | trans:'register.display_name_label'}}</label>
                <div class="input">
                    <img src="assets/ic_email.svg">
                    <input type="text" placeholder="{{'Unstable Unicorns' | trans:'register.display_name_placeholder'}}"
                        id="displayName" formControlName="displayName">
                </div>
                <div *ngIf="submitted && displayName?.invalid && (displayName?.dirty || displayName?.touched)"
                    class="error-container">
                    <div *ngIf="displayName?.errors?.['required']">
                        {{ 'Display name is required' | trans:'register.display_name_error_required' }}
                    </div>
                </div>
            </div>


            <div class="input-container">
                <label>{{ 'E-mail' | trans: 'register.email_label' }}</label>
                <div class="input">
                    <img src="assets/ic_email.svg">
                    <input type="email" placeholder="{{ 'example@gmail.com' | trans:'register.email_placeholder' }}"
                        id="email" formControlName="email">
                </div>
                <div *ngIf="submitted && email?.invalid && (email?.dirty || email?.touched)" class="error-container">

                    <div *ngIf="email?.errors?.['required']">
                        {{ "E-mail field is required" | trans:'register.email_error_required' }}
                    </div>
                    <div *ngIf="email?.errors?.['email']">
                        {{ "Invalid format" | trans:'register.email_error_invalid_email' }}
                    </div>
                </div>
            </div>

            <div class="input-container">
                <label> {{ "Password" | trans: 'register.password_label' }}</label>
                <div class="input">
                    <img src="assets/ic_password.svg">
                    <input type="password" placeholder="{{ '****' | trans: 'register.password_placeholder' }}"
                        name="password" formControlName="password">
                </div>
                <div *ngIf="submitted && password?.invalid && (password?.dirty || password?.touched)"
                    class="error-container">

                    <div *ngIf="password?.errors?.['required']">
                        {{ 'Password field is required' | trans: 'register.password_error_required' }}
                    </div>
                </div>
            </div>
            <button class="register-button">{{ 'Sign up' | trans: 'register.submit_button' }}</button>
            <!--      <div class="register-sso-button" (click)="onSSORegisterClicked()">-->
            <!--        <img src="assets/ic_google_mono.svg">-->
            <!--        Sign in with Google-->
            <!--      </div>-->
        </form>
        <div class="register-state-success" *ngIf="state == 'register-success'">
            <h1>{{"Sign up" | trans:'register.header'}}</h1>
            <div>{{ "Check your mailbox to confirm your registration" | trans:'register.result_success' }}</div>
        </div>
    </div>
</div>
<app-footer></app-footer>
