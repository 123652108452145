import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {LegalVM} from "../../../model/viewModel/legal.vm";
import {retry, switchMap} from "rxjs";
import {LegalService} from "../../../service/legal.service";

export const TYPE_TERMS_OF_CONDITIONS = "toc"
export const TYPE_PRIVACY_POLICY = "pp"

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  data: LegalVM | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private legalService: LegalService,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(
        switchMap(data => {
          let type = data["type"];
          if (type == TYPE_TERMS_OF_CONDITIONS) {
            return this.legalService.getTermsOfConditions()
          }else {
            return this.legalService.getPrivacyPolicy()
          }
        }),
        retry({
          delay: 1000
        })
      )
      .subscribe({
        next: (legal: LegalVM) => {
          this.data = legal;
        }
      });
  }

}
