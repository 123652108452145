import {StorageService} from "../storage.service";
import {HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponseBase} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";


export class RequestBuilder {

  static create(url: string, fullPath: boolean = false): RequestBuilder {
    return new RequestBuilder(url, fullPath);
  }

  _url: string;
  _withAuthHeaderInjection: boolean = false;
  _withLocaleParamsInjection: boolean = false;
  _locale?: string = undefined;
  _body?: any = undefined;
  _additionalParams = {};
  _fullPath: boolean;
  _customHeaders = {};

  private constructor(url: string, fullPath: boolean) {
    this._url = url;
    this._fullPath = fullPath;
  }

  auth(): this {
    this._withAuthHeaderInjection = true;
    return this;
  }

  withHeader(name: string, value: string): this {
    this._customHeaders[name] = value;
    return this
  }

  locale(locale?: string): this {
    this._locale = locale;
    this._withLocaleParamsInjection = true;
    return this;
  }

  withParam(name: string, value: any): this {
    this._additionalParams[name] = value;
    return this;
  }

  body(body: any): this {
    this._body = body;
    return this
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private storageService: StorageService,
    private httpClient: HttpClient,
  ) {
  }

  get<T>(builder: RequestBuilder): Observable<T> {
    return this.httpClient.get<T>(this.createUrl(builder), {
      params: this.createParams(builder),
      headers: this.createHeaders(builder),
      responseType: "json"
    });
  }

  post<T>(builder: RequestBuilder) {
    return this.httpClient.post<T>(
      this.createUrl(builder),
      builder._body,
      {
        headers: this.createHeaders(builder),
        params: this.createParams(builder),
        responseType: "json"
      }
    );
  }

  delete<T>(builder: RequestBuilder) {
    return this.httpClient.delete<T>(
      this.createUrl(builder),
      {
        headers: this.createHeaders(builder),
        params: this.createParams(builder),
        responseType: "json"
      }
    );
  }

  private createHeaders(builder: RequestBuilder): HttpHeaders {
    let headers = new HttpHeaders();
    if (builder._withAuthHeaderInjection) {
      headers = headers.set("Authorization", `Bearer ${this.storageService.authToken}`);
    }
    for (let key of Object.keys(builder._customHeaders)) {
      headers = headers.set(key, builder._customHeaders[key]);
    }
    return headers;
  }

  private createParams(builder: RequestBuilder): HttpParams {
    let params = new HttpParams();
    if (builder._withLocaleParamsInjection) {
      params = params.set("populate", "deep");
      params = params.set("locale", builder._locale ?? this.storageService.locale)
    }
    Object.keys(builder._additionalParams).forEach(key => {
      params = params.set(key, builder._additionalParams[key]);
    });
    return params;
  }


  private createUrl(builder: RequestBuilder) {
    let targetUrl = environment.baseApiUrl;
    if (builder._fullPath) {
      targetUrl = environment.baseUrl;
    }
    return targetUrl.replace(/\/+$/g, '') + builder._url;
  }
}
