<div class="container-calculator">
  <div class="container container-section">
    <div class="image-container" *ngIf="data?.image?.url">
      <img src="{{data?.image?.url}}">
    </div>
    <div class="text-container">
      <h2>{{ "Calculator" | trans: 'main.calculator_title' }}</h2>
      <p>{{ data.content }}</p>
    </div>
  </div>
</div>
