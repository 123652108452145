<app-menu></app-menu>
<div class="page page-article">
  <div class="container" *ngIf="data">
    <div class="cover-image-container">
      <div class="title-container">
        <h1>{{data.title}}</h1>
        <small>{{data.createdAt | date:"MMMM d, y H:mm" }}</small>
      </div>
      <img src="{{data.cover.url}}" *ngIf="data.cover?.url">
      <div class="no-image-overlay" *ngIf="!data.cover?.url"></div>
    </div>
    <div class="article-content">
      <div class="content" [innerHTML]="data.content | marked"></div>
    </div>
  </div>
  <app-loading *ngIf="!data"></app-loading>
</div>
<app-footer></app-footer>
