<app-menu currentMenu="services"></app-menu>
<div class="page page-services">
  <div class="container" *ngIf="!isLoading">
    <h1 class="pb-2">{{ "Services" | trans: 'services.list_service_header' }}</h1>
    <div class="item-container">
      <div class="item" *ngFor="let service of services">
        <img *ngIf="service?.image?.url" class="background" src="{{ service.image.url }}">
        <div class="background-overlay"></div>
        <div class="content">
          <h2>{{ service.name }}</h2>
          <div class="description" [innerHTML]="service.description | marked"></div>
          <div class="price-container">
            <div class="price">{{ service.price }} HUF</div>
            <div class="vat-line">{{ "VAT included" | trans: 'services.list_service_vat' }}</div>
          </div>
          <div class="purchase-button">
            <button
              (click)="onPurchaseServiceButtonClicked(service)">{{ "Purchase" | trans: 'services.list_service_cta' }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <h1 class="pt-4 pb-2">{{ "Plans" | trans: 'services.list_plan_header' }}</h1>
    <div class="item-container">
      <div class="item" *ngFor="let plan of plans">
        <img class="background" src="assets/bg_services_plan.png">
        <div class="content">
          <h2>{{ plan.name }}</h2>
          <div class="description">
            <div class="description-item" *ngFor="let planBenefit of plan.benefits">
              <div class="icon">
                <img src="{{ planBenefit.icon.url }}">
              </div>
              <h3>{{ planBenefit.name }}</h3>
              <div>{{ planBenefit.description }}</div>
            </div>
          </div>
          <div class="price-container">
            <div class="price">{{ plan.price }} HUF</div>
            <div class="vat-line">{{ "VAT included" | trans: 'services.list_plan_vat' }}</div>
          </div>
          <div class="purchase-button">
            <button (click)="onPurchasePlanButtonClicked(plan)">{{ "Purchase" | trans: 'services.list_plan_cta' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading *ngIf="isLoading"></app-loading>
</div>
<app-footer></app-footer>
