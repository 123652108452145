import {Injectable} from "@angular/core";
import {GlobalDataService} from "./global-data.service";
import {TranslationDTO} from "../model/http/translation.dto";

type Flatten<T> = {
  [K in keyof T as `${string & K}.${string & keyof T[K]}`]: T[K][keyof T[K]]
};

export type TranslateProperties = keyof Flatten<TranslationDTO>;


@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(
    private globalDataService: GlobalDataService
  ){}

  trans(key: TranslateProperties, defaultValue: string){
    let keyChain = (key + "").split(".");
    let targetValue = keyChain.reduce((acc, item) => {
      if(acc) return acc[item];
      return null;
    }, this.globalDataService.translations);
    //let targetValue = this.globalDataService.translations[key];
    if(!targetValue){
      console.warn(`Translation does not exists for this field: ${key}`);
      return defaultValue;
    }
    return targetValue;
  }
}
