<ng-template #templateMainMenu>
  <ul class="main-menu">
    <li [class.active]="currentMenu == 'main'">
      <a href="#" (click)="onMenuItemClicked('main')">{{ "Main" | trans: 'menu.item_main' }}</a>
    </li>
    <li [class.active]="currentMenu == 'calculator'">
      <a href="#" (click)="onMenuItemClicked('calculator')">{{ "Calculator" | trans: 'menu.item_calculator' }} </a>
    </li>
    <li [class.active]="currentMenu == 'test'">
      <a href="#" (click)="onMenuItemClicked('test')">{{ "Test" | trans: 'menu.item_test' }}</a>
    </li>
    <li [class.active]="currentMenu == 'services'">
      <a href="#" (click)="onMenuItemClicked('services')">{{ "Services" | trans: 'menu.item_service_list' }}</a>
    </li>
  </ul>
</ng-template>
<ng-template #templateAnonymousAuthMenu>
  <div class="auth-container" *ngIf="!authService.isLoggedIn">
    <a class="btn btn-sign-in"
       (click)="onAuthMenuItemClicked('login')">{{ "Sign in" | trans: "menu.button_login" }}</a>
    <a class="btn btn-sign-up"
       (click)="onAuthMenuItemClicked('register')">{{ "Sign up" | trans: "menu.button_register" }}</a>
  </div>
</ng-template>


<nav class="main-navigator">
  <div class="mobile-menu d-md-none">
    <div class="menu-icon" (click)="onMobileMenuClicked()">
      <img src="assets/ic_mobile_menu.svg">
    </div>
    <div class="logo-container">
      <img src="assets/menu_logo.svg">
    </div>

    <div class="menu" [class.open]="menuOpened" (click)="false">
      <ng-container *ngTemplateOutlet="templateMainMenu"></ng-container>

      <div class="menu-footer">
        <ng-container *ngTemplateOutlet="templateAnonymousAuthMenu"></ng-container>
        <div class="text-center">
          <div class="language-selector">
            <ul>
              <li *ngFor="let locale of gds.locales" [class.active]="selectedLanguage == locale.code">
                <a href="#" (click)="changeLocale(locale.code)">
                  {{locale.code}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="user-container" *ngIf="authService.isLoggedIn" (click)="onMobileProfileClicked()">
      <div class="profile-picture-container">
        <img *ngIf="false">
        <div *ngIf="true" class="no-profile-picture">
          {{ authService.currentUser.username.toUpperCase().charAt(0) }}
        </div>
      </div>
    </div>

    <div class="user-menu-container" *ngIf="authMenuOpened">
      <div class="user-menu">
        <ul>
          <li>
            <img src="assets/ic_profile.svg">
            <a href="#" (click)="onAuthMenuItemClicked('profile')">{{ "Profile" | trans: 'menu.user_menu_button_profile'  }}</a>
          </li>
          <li>
            <img src="assets/ic_settings.svg">
            <a href="#" (click)="onAuthMenuItemClicked('settings')">{{ "Settings" | trans: 'menu.user_menu_button_settings'  }}</a>
          </li>
          <li>
            <img src="assets/ic_logout.svg">
            <a href="#" (click)="onAuthMenuItemClicked('logout')">{{ "Logout" | trans: 'menu.user_menu_button_logout'  }}</a>
          </li>
        </ul>
      </div>
    </div>


  </div>
  <div class="desktop-menu d-none d-md-grid">
    <div class="logo-container">
      <img src="assets/menu_logo.svg">
    </div>

    <div class="menu">
      <ng-container *ngTemplateOutlet="templateMainMenu"></ng-container>
      <ul class="language-selector">
        <li *ngFor="let locale of gds.locales" [class.active]="selectedLanguage == locale.code">
          <a href="#" (click)="changeLocale(locale.code)">
            {{locale.code}}
          </a>
        </li>
      </ul>
    </div>

    <ng-container *ngTemplateOutlet="templateAnonymousAuthMenu"></ng-container>
    <div class="user-container" *ngIf="authService.isLoggedIn">
      <div class="profile-picture-container">
        <img *ngIf="false">
        <div *ngIf="true" class="no-profile-picture">
          {{ authService.currentUser.username.toUpperCase().charAt(0) }}
        </div>
      </div>
      <div class="user-info-container">
        <div class="name"> {{ authService.currentUser.username.toUpperCase() }} </div>
        <div class="email"> {{ authService.currentUser.email.toLowerCase() }}  </div>
      </div>
      <div class="user-menu-container">
        <div class="user-menu">
          <ul>
            <li>
              <img src="assets/ic_profile.svg">
              <a href="#" (click)="onAuthMenuItemClicked('profile')">{{ "Profile" | trans: 'menu.user_menu_button_profile'  }}</a>
            </li>
            <li>
              <img src="assets/ic_settings.svg">
              <a href="#" (click)="onAuthMenuItemClicked('settings')">{{ "Settings" | trans: 'menu.user_menu_button_settings'  }}</a>
            </li>
            <li>
              <img src="assets/ic_logout.svg">
              <a href="#" (click)="onAuthMenuItemClicked('logout')">{{ "Logout" | trans: 'menu.user_menu_button_logout'  }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
