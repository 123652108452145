import {Component, OnInit} from '@angular/core';
import {CourseDTO, UniversityDTO} from "../../../model/http/course.dto";
import {CourseService} from "../../../service/course.service";
import {zip} from "rxjs";

import {
  Model as CourseSelectionModel,
  Event as CourseSelectionEvent
} from "./calculator-card-course-selector/calculator-card-course-selector.component";
import {
  Model as PerformanceInputModel,
  Event as PerformanceInputEvent
} from "./calculator-card-performance-input/calculator-card-performance-input.component";


export type StateType = "courseSelection" | "performanceInput" | "result";
export const StateArray: StateType[] = ["courseSelection", "performanceInput", "result"];
export type Model = {
  courseSelectionResult?: CourseSelectionModel,
  performanceInputResult?: PerformanceInputModel,
};

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  currentState: StateType;
  model: Model = <Model>{};

  ngOnInit(): void {
    this.currentState = "courseSelection";
  }

  isInState(state: StateType): boolean {
    return this.currentState == state;
  }


  containsState(type: StateType): boolean {
    let targetStateIndex = StateArray.indexOf(type);
    let currentStateIndex = StateArray.indexOf(this.currentState);
    return currentStateIndex >= targetStateIndex;
  }


  onCourseSelectionEvent(event: CourseSelectionEvent) {
    if (event.type == "next") {
      this.model.courseSelectionResult = event.model;
    }
    this.transitionToState(event.type);
  }

  onPerformanceInputEvent(event: PerformanceInputEvent) {
    if (event.type == "next") {
      this.model.performanceInputResult = event.model;
    }
    this.transitionToState(event.type);
  }

  private transitionToState(event: "next" | "back") {
    let targetIndexDiff = event == "next" ? 1 : -1;
    let currentIndex = StateArray.indexOf(this.currentState);
    let targetIndex = Math.abs(currentIndex + targetIndexDiff) % StateArray.length;
    this.currentState = StateArray[targetIndex];
  }
}
