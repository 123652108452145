<div class="container-fluid section-header">
  <img *ngIf="data?.background?.url" [src]="data.background.url">
  <div class="header-section-container">
    <h1>{{data.title}}</h1>
    <p>{{data.subtitle}}</p>
    <div>
      <a routerLink="test">{{ "Take a test" | trans: 'main.header_cta' }}</a>
    </div>
  </div>
</div>
