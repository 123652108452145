import {Injectable} from '@angular/core';
import {ApiService, RequestBuilder} from "./http/api.service";
import {ArticleVM} from "../model/viewModel/article.vm";
import {map, Observable} from "rxjs";
import {ArticleDTO} from "../model/http/article.dto";
import {MediaMapperService} from "../mapper/media-mapper.service";
import {ListResponseDTO, ResponseDTO} from "../model/http/base.dto";

const MAIN_LIST_PAGE_SIZE = 4
const EXCERPT_LENGTH = 150;

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  constructor(
    private api: ApiService,
    private mediaMapper: MediaMapperService,
  ) {
  }

  getMainArticles(): Observable<ArticleVM[]> {
    return this.api.get<ListResponseDTO<ArticleDTO>>(RequestBuilder
      .create(`/articles`)
      .locale()
      .withParam("sort", "createdAt:desc")
      .withParam("pagination[pageSize]", MAIN_LIST_PAGE_SIZE)
    ).pipe(
      map(response => {
        return response.data.map(articleContainer => {
          return this.mapArticleToVM(articleContainer.id, articleContainer.attributes)
        })
      })
    )
  }

  getArticleById(id: number) : Observable<ArticleVM> {
    return this.api.get<ResponseDTO<ArticleDTO>>(
      RequestBuilder.create(`/articles/${id}`)
        .locale()
    ).pipe(
      map(response => {
        return this.mapArticleToVM(response.data.id, response.data.attributes)
      })
    )
  }

  private mapArticleToVM(id: number, dto: ArticleDTO): ArticleVM {
    console.log(dto.content.substring(0, EXCERPT_LENGTH));
    return {
      id: id,
      title: dto.title,
      content: dto.content,
      excerpt: dto.content.substring(0, EXCERPT_LENGTH) + ((dto.content.length > EXCERPT_LENGTH) ? "..." : ""),
      cover: this.mediaMapper.selectImageFromResponse(dto.cover)!!,
      createdAt: new Date(Date.parse(dto.createdAt))
    };
  }
}
