<app-menu></app-menu>
<div class="page page-user-settings">
  <div class="user-profile-container">
    <div class="container p-4">
      <div class="mb-4">
        <app-user-profile-test-result></app-user-profile-test-result>
        <app-user-profile-transactions></app-user-profile-transactions>
        <app-user-profile-files></app-user-profile-files>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
