import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {GlobalDataService} from "../../../service/global-data.service";

export type ErrorDetails = {
  title: string;
};


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorDetails: undefined | ErrorDetails;
  constructor(
    private activatedRoute: ActivatedRoute,
    private globalDataService: GlobalDataService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(parameters => {
      console.log(parameters["code"]);
      switch (parseInt(parameters["code"])) {
        case 404:
          this.errorDetails = {
            title: this.globalDataService.translations['error']['error_not_found'] ?? "Page not found",
          };
          break;
        default:
          this.errorDetails = {
            title: this.globalDataService.translations['error']['error_general'] ?? "Unknown error, try again later",
          };
          break;
      }
    });
  }

}
