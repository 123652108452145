import {Injectable} from '@angular/core';
import {CourseTagDTO, LocationGroupDTO, TestDTO} from "../model/http/test.dto";
import {map, Observable, tap} from "rxjs";
import {ApiService, RequestBuilder} from "./http/api.service";
import {
  CreateTestResultItemDTO,
  CreateTestResultItemResponseDTO,
  TestResultDTO,
  TestResultListDTO
} from "../model/http/test-result.dto";
import {AuthService} from "./auth.service";
import {StorageService} from "./storage.service";
import {ListResponseDTO} from "../model/http/base.dto";

@Injectable({
  providedIn: 'root'
})
export class PersonalityTestService {

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private storageService: StorageService,
  ) {
  }

  getTests(): Observable<TestDTO[]> {
    return this.api.get<ListResponseDTO<TestDTO>>(RequestBuilder.create("/tests").locale()).pipe(map(item => item.data.map(a => a.attributes)));
  }

  getCourseTags(): Observable<CourseTagDTO[]> {
    return this.api.get<ListResponseDTO<CourseTagDTO>>(RequestBuilder.create("/course-tags").locale()).pipe(map(item => item.data.map(a => {
      return {
        ...a.attributes,
        id: a.id
      };
    })));
  }

  getLocations(): Observable<LocationGroupDTO[]> {
    return this.api.get<ListResponseDTO<LocationGroupDTO>>(RequestBuilder.create("/location-groups").locale()).pipe(
      map(item => item.data.map(it => {
        return {
          ...it.attributes,
          id: it.id
        };
      }))
    );
  }

  getTestResults(): Observable<TestResultListDTO> {
    return this.api.get(RequestBuilder.create(`/test-result`).locale().auth());
  }

  getTestResult(id: number): Observable<TestResultDTO> {
    return this.api.get(RequestBuilder.create(`/test-result/${id}`).locale().auth());
  }

  createTestResult(items: CreateTestResultItemDTO[]): Observable<CreateTestResultItemResponseDTO> {
    let request = RequestBuilder.create("/test-result/create").locale().body(items);
    if (this.authService.isLoggedIn) {
      request = request.auth();
    }
    let $source = this.api.post<CreateTestResultItemResponseDTO>(request)
      .pipe(
        tap({
          next: (response) => {
            if (!this.authService.isLoggedIn) {
              this.storageService.pendingTestResult = response.id
            }
          }
        })
      );
    if (this.authService.isLoggedIn) {
      $source = $source.pipe(this.authService.createTokenErrorHandler());
    }
    return $source;
  }

  attachToUser(id: number): Observable<void> {
    return this.api.post(
      RequestBuilder.create(`/test-result/${id}/attach`)
        .auth()
    ).pipe(this.authService.createTokenErrorHandler());
  }

}
