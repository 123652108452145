import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { DialogService } from 'src/app/service/dialog.service';
import { GlobalDataService } from 'src/app/service/global-data.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  submitted: boolean = false;
  state: "register-init" | "register-success" = "register-init";

  registerForm = new FormGroup({
    displayName: new FormControl("", [
      Validators.required
    ]),
    email: new FormControl("", [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl("", [
      Validators.required
    ])
  })

  get displayName () {
    return this.registerForm.get("displayName");
  }
  get email() {
    return this.registerForm.get("email");
  }

  get password() {
    return this.registerForm.get("password")
  }
  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private router: Router,
    public gds: GlobalDataService
  ) {

  }

  ngOnInit(): void {
  }

  onSSORegisterClicked() {
    //TODO: Not implemented yet
  }

  onRegisterFormSubmitted() {
    this.submitted = true;
    this.registerForm.markAllAsTouched()
    if (this.registerForm.invalid) {
      return;
    }
    let model = this.registerForm.value;
    this.authService.register(model.displayName!, model.email!, model.password!).subscribe({
      next: () => {
        this.state = "register-success";
      },
      error: () => {
        this.dialogService.show({
          title: this.gds.translations["register"]["submit_error_invalid_credentials_title"],
          content: this.gds.translations["register"]["submit_error_invalid_credentials_description"],
          buttons: {
            positive: {
              label: this.gds.translations["register"]["submit_error_invalid_credentials_button"]
            }
          },
          type: "error"
        });
      }
    });

  }
}
