import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CpiInputChanged} from "./cpi-input-row/cpi-input-row.component";
import {HighSchoolSubjectTagVM, HighSchoolSubjectVM} from "../../../../model/viewModel/calculator.vm";
import {CourseService} from "../../../../service/course.service";
import {CalculatorService} from "../../../../service/calculator.service";
import {GlobalDataService} from "../../../../service/global-data.service";
import {TranslationService} from "../../../../service/translation.service";

export type Event = {
  type: "back"
} | {
  type: "next",
  model: Model
};

export type PerformanceEntry = {
  subject: HighSchoolSubjectVM;
  value: number;
  isAdvanced: boolean;
};

export type Model = {
  lastYearResult: Record<HighSchoolSubjectTagVM, PerformanceEntry>,
  graduationResult: Record<HighSchoolSubjectTagVM, PerformanceEntry>
};

@Component({
  selector: 'app-calculator-card-performance-input',
  templateUrl: './calculator-card-performance-input.component.html',
  styleUrls: ['./calculator-card-performance-input.component.scss']
})
export class CalculatorCardPerformanceInputComponent implements OnInit {

  private subjects: HighSchoolSubjectVM[] = [];
  isLoading: boolean = true;

  @Input() isInActiveState: boolean = false;
  @Output() output = new EventEmitter<Event>();
  private model: Model = <Model>{
    graduationResult: {},
    lastYearResult: {}
  };

  constructor(
    private calculatorService: CalculatorService,
    private translatorService: TranslationService,
  ) { }

  ngOnInit(): void {
    this.calculatorService.getHighSchoolSubjects()
      .subscribe(subjects => {
        this.subjects = subjects;
        this.isLoading = false;
      });
  }

  getTagOrder() : HighSchoolSubjectTagVM[]{
    return [
      "literature",
      "history",
      "math",
      "language",
      "none"
    ];
  }

  onLastYearPerformanceChanged($event: CpiInputChanged) {
    console.log("lyp", $event);
    this.model.lastYearResult[$event.subject.tag] = $event;
  }

  onGraduationResultPerformanceChanged($event: CpiInputChanged) {
    console.log("gp", $event);
    this.model.graduationResult[$event.subject.tag] = $event;
  }

  onNextButtonClicked(){
    this.output.emit({ type: "next", model: this.model});
  }

  onBackButtonClicked(){
    this.output.emit({ type: "back" });
  }



  getSubjectsFor(tag: HighSchoolSubjectTagVM): HighSchoolSubjectVM[] {
    return this.subjects.filter(item => item.tag == tag)
  }

  getLastYearResultLabelForTag(tag: HighSchoolSubjectTagVM): string {
    return this.getGraduationResultLabelForTag(tag);
  }

  getGraduationResultLabelForTag(tag: HighSchoolSubjectTagVM): string {
    switch(tag){
      case "literature": return this.translatorService.trans('calculator.performance_input_literature', "Literature");
      case "history":return this.translatorService.trans('calculator.performance_input_history', "History");
      case "math": return this.translatorService.trans('calculator.performance_input_math', "Math");
      case "language": return this.translatorService.trans('calculator.performance_input_language', "Language");
      case "none": return this.translatorService.trans('calculator.performance_input_hint', "Chosen subject");
      default: return undefined;
    }
  }
}
