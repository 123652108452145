import {Component, OnInit} from '@angular/core';
import {GlobalDataService} from "./service/global-data.service";
import {delay, Observable, retry, zip, zipAll} from "rxjs";
import {AuthService} from "./service/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'project';
  isLoaded = false;

  constructor(
    private globalDataService: GlobalDataService,
    private authService: AuthService,
  ){}
  ngOnInit(): void {
    let sources: Array<Observable<void>> = [];
    if(this.authService.isLoggedIn){
      sources.push(this.authService.updateUserInfo());
    }
    sources.push(this.globalDataService.load());

    zip(sources)
      .pipe(
        retry({
          delay: 1000
        })
      )
      .subscribe({
        next: () => {
          this.isLoaded = true;
        }
      });
  }


}
