<app-menu currentMenu="test"></app-menu>
<div class="page page-test">
  <app-loading *ngIf="!isLoaded"></app-loading>
  <div class="test-container" *ngIf="isLoaded">
    <div class="test-card">
      <div class="result-page">
        <div class="result-card">

          <div class="result-percentage">
            <div class="result-row-new" *ngFor="let result of data.result">
              <div class="label-container">
                <div class="label">{{result.course_tag.name}}</div>
                <div class="percentage">{{getPercentage(result)}}%</div>
              </div>
              <div class="percentage-container">
                <div
                  class="percentage-foreground high"
                  [class.low]="getPercentage(result) <= 30"
                  [class.medium]="getPercentage(result) <= 60 && getPercentage(result) > 30"
                  [class.high]="getPercentage(result) > 60"
                  [style]="{width: getPercentage(result)+ '%'}"
                ></div>
              </div>
            </div>
          </div>

          <div class="result-summary">
            <h3>{{ data.recommendedDiscipline?.name }}</h3>
          </div>
        </div>
        <div class="recommendation">
          <div class="purchase-service" *ngIf="!data.unlocked">
            <div class="item">
              <h2>{{targetService.name}}</h2>
              <div class="item-content">
                <div class="image-container">
                  <img [src]="targetService.image.url">
                </div>
                <div class="content-container">
                  <div class="description-container" [innerHTML]="targetService.description | marked"></div>
                  <div class="control-container">
                      <span class="price-container">
                        Price:
                        <div class="amount">{{targetService.price}}</div>
                        <div class="currency">HUF</div>
                      </span>
                    <div class="purchase-button">
                      <button (click)="onPurchaseButtonClicked()">{{ "Purchase" | trans: 'testresult.service_cta' }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="course-list" *ngIf="data.unlocked">
            <div class="course" *ngFor="let course of data.recommendedCourses.slice(0, 4)" (click)="onCourseDetailsClicked(course)">
              <img *ngIf="course.university.cover" src="{{ getCoverImage(course.university.cover).url }}">
              <h3>{{ course.university.name }}</h3>
              <small>{{ course.university.location}}</small>
              <h4>{{ course.name}}</h4>
              <p>{{ course.short_description }}</p>
              <div class="disciplines-container">
                <div class="discipline" *ngFor="let discipline of course.course_disciplines">{{ discipline.name }}</div>
              </div>
            </div>
          </div>
          <div class="additional-services">
            <a href="#" (click)="onAdditionalServicesClicked()">{{ "Additional services" | trans: 'testresult.service_additional_link' }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
