<div class="container container-plans">
  <h2>{{ "Plans" | trans: 'main.plans_title' }}</h2>
  <app-loading *ngIf="!plans"></app-loading>
  <div class="items" *ngIf="plans">
    <div class="plan" *ngFor="let planContainer of plans" [class.highlighted]="planContainer.isHighlighted">
      <h3>{{planContainer.plan.name}}</h3>
      <div class="price">
        <div class="amount">{{planContainer.plan.price | number}}</div>
        <div class="currency">HUF</div>
      </div>
      <div class="item-details" *ngFor="let benefit of planContainer.plan.benefits">
        <div class="item-row">
          <img src="{{benefit.icon?.url}}">
          <span>{{benefit.name}}</span>
        </div>
      </div>
      <div class="separator"></div>
      <a href="#" class="plan-cta" (click)="onPlanClicked(planContainer.plan)">{{ "Select plan" | trans: 'main.plans_cta' }}</a>
    </div>
  </div>
</div>
