<app-menu currentMenu="calculator"></app-menu>
<div class="page page-calculator">
  <div class="calculator-container">
    <div class="container p-4">

      <app-calculator-card-info></app-calculator-card-info>

      <app-calculator-card-course-selector
        (output)="onCourseSelectionEvent($event)"
        [isInActiveState]="isInState('courseSelection')"
        *ngIf="containsState('courseSelection') && currentState != 'result'"
      ></app-calculator-card-course-selector>

      <app-calculator-card-performance-input
        (output)="onPerformanceInputEvent($event)"
        [isInActiveState]="isInState('performanceInput')"
        *ngIf="containsState('performanceInput') && currentState != 'result'"
      ></app-calculator-card-performance-input>

      <app-calculator-card-estimation
        [model]="model"
        *ngIf="currentState == 'result'"
      ></app-calculator-card-estimation>
    </div>
  </div>
</div>
<app-footer></app-footer>
