import {Component, Input, OnInit} from '@angular/core';
import {StorageService} from "../../../service/storage.service";
import {GlobalDataService} from "../../../service/global-data.service";
import {AuthService} from "../../../service/auth.service";
import {Router} from "@angular/router";

export type MenuItem = "main" | "calculator" | "test" | "services";
export type AnonymousAuthMenuItems = "login" | "register";
export type AuthenticatedAuthMenuItems = "logout" | "settings" | "profile";
export type AuthMenuItem = AuthenticatedAuthMenuItems | AnonymousAuthMenuItems;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  selectedLanguage!: string;
  @Input() currentMenu!: MenuItem;
  menuOpened: boolean = false;
  authMenuOpened: boolean = false;

  constructor(
    private storageService: StorageService,
    public gds: GlobalDataService,
    public authService: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.selectedLanguage = this.storageService.locale
  }

  changeLocale(locale: string) {
    this.storageService.locale = locale;
    location.reload();
    return false;
  }

  onMobileMenuClicked() {
    this.menuOpened = !this.menuOpened;
  }

  onMenuItemClicked(menu: MenuItem) {
    const routeMapping = {
      'main': '/',
      'calculator': '/calculator',
      'test': '/test',
      'services': '/services'
    };

    if (routeMapping.hasOwnProperty(menu)) {
      this.router.navigate([routeMapping[menu]]);
    } else {
      console.error(`!!! MenuItem not implemented: ${menu}`);
    }
    this.menuOpened = false;
    this.authMenuOpened = false;
    return false;
  }

  onAuthMenuItemClicked(menu: AuthMenuItem) {
    if (menu == "logout") {
      this.authService.logout();
      this.router.navigate(["/"]);
      return false;
    }
    const routeMapping = {
      "login": "/login",
      "register": "/register",
      "settings": "/user/settings",
      "profile": "/user/profile"
    };
    if (routeMapping.hasOwnProperty(menu)) {
      this.router.navigate([routeMapping[menu]]);
    } else {
      console.error(`!!!!AuthMenu item not implemented: ${menu}`);
    }
    this.menuOpened = false;
    this.authMenuOpened = false;
    return false;
  }

  onMobileProfileClicked() {
    this.authMenuOpened = !this.authMenuOpened;
  }
}
