import {Pipe, PipeTransform} from '@angular/core';
import {TranslateProperties, TranslationService} from "../service/translation.service";


@Pipe({
  name: 'trans'
})
export class TranslatePipe implements PipeTransform {
  constructor(
    private translationService: TranslationService
  ) {
  }

  transform(value: string, ...args: TranslateProperties[]): string {
    if (args.length < 1) {
      console.error("Miss use of the trans pipe");
      return value;
    }
    return this.translationService.trans(args[0], value);

  }

}
