import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './component/page/main/main.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from './component/part/menu/menu.component';
import { FooterComponent } from './component/part/footer/footer.component';
import { HttpClientModule } from "@angular/common/http";
import { MainHeaderComponent } from './component/page/main/main-header/main-header.component';
import { MainAboutComponent } from './component/page/main/main-about/main-about.component';
import { MainCalculatorComponent } from './component/page/main/main-calculator/main-calculator.component';
import { MainArticleComponent } from './component/page/main/main-article/main-article.component';
import { LoadingComponent } from './component/part/loading/loading.component';
import { ArticleComponent } from './component/page/article/article.component';
import { MarkedPipe } from "./pipe/marked.pipe";
import { MainPlansComponent } from './component/page/main/main-plans/main-plans.component';
import { ServicesComponent } from './component/page/services/services.component';
import { LegalComponent } from './component/page/legal/legal.component';
import { LoginComponent } from './component/page/login/login.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogContainerComponent } from './component/part/dialog-container/dialog-container.component';
import { ErrorComponent } from './component/page/error/error.component';
import { RegisterComponent } from './component/page/register/register.component';
import { ForgottPasswordComponent } from './component/page/forgott-password/forgott-password.component';
import { ResetPasswordComponent } from './component/page/reset-password/reset-password.component';
import { TranslatePipe } from './pipe/translate.pipe';
import { PersonalityTestComponent } from './component/page/personality-test/personality-test.component';
import { PaymentComponent } from './component/page/payment/payment.component';
import { PersonalityTestResultComponent } from './component/page/personality-test-result/personality-test-result.component';
import {CourseDetailsComponent} from "./component/page/course-details/course-details.component";
import { UserSettingsComponent } from './component/page/user-settings/user-settings.component';
import { UserProfileComponent } from './component/page/user-profile/user-profile.component';
import { UserSettingsPasswordComponent } from './component/page/user-settings/user-settings-password/user-settings-password.component';
import { UserProfileTestResultComponent } from './component/page/user-profile/user-profile-test-result/user-profile-test-result.component';
import { UserProfileTransactionsComponent } from './component/page/user-profile/user-profile-transactions/user-profile-transactions.component';
import { CalculatorComponent } from './component/page/calculator/calculator.component';
import { CalculatorCardInfoComponent } from './component/page/calculator/calculator-card-info/calculator-card-info.component';
import { CalculatorCardCourseSelectorComponent } from './component/page/calculator/calculator-card-course-selector/calculator-card-course-selector.component';
import { CalculatorCardPerformanceInputComponent } from './component/page/calculator/calculator-card-performance-input/calculator-card-performance-input.component';
import { CalculatorCardEstimationComponent } from './component/page/calculator/calculator-card-estimation/calculator-card-estimation.component';
import { CpiInputRowComponent } from './component/page/calculator/calculator-card-performance-input/cpi-input-row/cpi-input-row.component';
import { UserProfileFilesComponent } from './component/page/user-profile/user-profile-files/user-profile-files.component';
import {AutofocusDirective} from "./directive/autofocus.directive";

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MenuComponent,
    FooterComponent,
    MainHeaderComponent,
    MainAboutComponent,
    MainCalculatorComponent,
    MainArticleComponent,
    LoadingComponent,
    ArticleComponent,
    MarkedPipe,
    MainPlansComponent,
    ServicesComponent,
    LegalComponent,
    LoginComponent,
    DialogContainerComponent,
    ErrorComponent,
    RegisterComponent,
    ForgottPasswordComponent,
    ResetPasswordComponent,
    TranslatePipe,
    PersonalityTestComponent,
    PaymentComponent,
    PersonalityTestResultComponent,
    CourseDetailsComponent,
    UserSettingsComponent,
    UserProfileComponent,
    UserSettingsPasswordComponent,
    UserProfileTestResultComponent,
    UserProfileTransactionsComponent,
    CalculatorComponent,
    CalculatorCardInfoComponent,
    CalculatorCardCourseSelectorComponent,
    CalculatorCardPerformanceInputComponent,
    CalculatorCardEstimationComponent,
    CpiInputRowComponent,
    UserProfileFilesComponent,

    AutofocusDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
