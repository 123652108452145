import {Injectable} from '@angular/core';
import {ApiService, RequestBuilder} from "./http/api.service";
import {map, Observable} from "rxjs";
import {MainVM} from "../model/viewModel/main.vm";
import {MediaMapperService} from "../mapper/media-mapper.service";
import {ResponseDTO} from "../model/http/base.dto";
import {MainDTO} from "../model/http/main.dto";

@Injectable({
  providedIn: 'root'
})
export class MainDataService {

  constructor(
    private api: ApiService,
    private mediaMapperService: MediaMapperService,
  ) {
  }

  getData(): Observable<MainVM> {
    return this.api.get<ResponseDTO<MainDTO>>(RequestBuilder.create("/main").locale())
      .pipe(
        map(data => {
          console.log(data);
          return <MainVM>{
            header: {
              title: data.data.attributes.header.title,
              subtitle: data.data.attributes.header.subtitle,
              background: this.mediaMapperService.selectImageFromResponse(data.data.attributes.header.background)
            },
            about: {
              title: data.data.attributes.about.title,
              content: data.data.attributes.about.content,
              image: this.mediaMapperService.selectImageFromResponse(data.data.attributes.about.image)
            },
            calculator: {
              title: data.data.attributes.calculator.title,
              content: data.data.attributes.calculator.content,
              image: this.mediaMapperService.selectImageFromResponse(data.data.attributes.calculator.image)
            }
          }
        })
      )
  }
}
