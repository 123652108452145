import {Injectable} from "@angular/core";
import {ApiService, RequestBuilder} from "./http/api.service";
import {map, Observable} from "rxjs";
import {CalculatorCourseItemVM, HighSchoolSubjectVM} from "../model/viewModel/calculator.vm";
import {ListResponseDTO} from "../model/http/base.dto";
import {HighSchoolSubjectDTO} from "../model/http/high.school.subject.dto";
import {CalculatorCourseItemDTO} from "../model/http/calculator.dto";

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  constructor(
    private api: ApiService
  ){}

  getCalculatorData() : Observable<CalculatorCourseItemVM[]> {
    return this.api.get<CalculatorCourseItemDTO[]>(
      RequestBuilder.create(`/calculator/courses`).locale()
    ).pipe(map(item => {
      console.log(item);
      return item.map(innerItem => {
        return <CalculatorCourseItemVM>{
          id: innerItem.id,
          name: innerItem.name,
          university: {
            id: innerItem.university.id,
            name: innerItem.university.name,
          },
          grad_req1: innerItem.grad_req1.map(req1 => this.mapHighSchoolSubjectDTO(req1)),
          grad_req2: innerItem.grad_req2.map(req2 => this.mapHighSchoolSubjectDTO(req2)),
          study_last_year_req: innerItem.study_last_year_req.map(req => this.mapHighSchoolSubjectDTO(req)),
          study_grad_req: innerItem.study_grad_req.map(req => this.mapHighSchoolSubjectDTO(req))
        }
      });
    }));
  }

  getHighSchoolSubjects() : Observable<HighSchoolSubjectVM[]>{
    return this.api.get<ListResponseDTO<HighSchoolSubjectDTO>>(
      RequestBuilder.create(`/high-school-subjects`).locale()
    ).pipe(map(item => {
      return item.data.map(subject => {
        return {
          id: subject.id,
          name: subject.attributes.name,
          tag: subject.attributes.tag,
        };
      });
    }));
  }


  private mapHighSchoolSubjectDTO(dto: HighSchoolSubjectDTO) : HighSchoolSubjectVM{
    return {
      id: dto.id,
      name: dto.name,
      tag: dto.tag
    };
  }
}
