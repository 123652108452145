<div class="card input-card mb-4 p-4">
  <div class="card-body">
    <app-loading *ngIf="isLoading"></app-loading>
    <div class="content-row row" *ngIf="!isLoading">
      <h3>{{ "Results" | trans: 'calculator.performance_input_hint' }}</h3>
      <div class="col-md-6">
        <h4>{{ "Last year results" | trans: 'calculator.performance_input_last_year_title' }}</h4>
        <app-cpi-input-row
          *ngFor="let tag of getTagOrder()"
          [label]="getLastYearResultLabelForTag(tag)"
          [subjects]="getSubjectsFor(tag)"
          [maxLength]="1"
          [levelDecoration]="false"
          (output)="onLastYearPerformanceChanged($event)"
        ></app-cpi-input-row>
      </div>

      <div class="col-md-6 grad-result">
        <h4>{{ "Graduation results" | trans: 'calculator.performance_input_grad_title' }}</h4>
        <app-cpi-input-row
          *ngFor="let tag of getTagOrder()"
          [label]="getGraduationResultLabelForTag(tag)"
          [subjects]="getSubjectsFor(tag)"
          [maxLength]="3"
          [levelDecoration]="true"
          (output)="onGraduationResultPerformanceChanged($event)"
        ></app-cpi-input-row>
      </div>
      <div class="footer-row text-center mt-3">
        <button class="btn btn-primary d-inline-block" (click)="onBackButtonClicked()">{{ "Back" | trans: 'calculator.button_prev' }}</button>
        <span class="d-inline-block m-2"></span>
        <button class="btn btn-primary d-inline-block" (click)="onNextButtonClicked()">{{ "Next" | trans: 'calculator.button_next' }}</button>
      </div>
    </div>
  </div>
</div>
