import {Component, OnInit} from '@angular/core';
import {GlobalDataService} from "../../../service/global-data.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  get currentYear(): number {
    return (new Date()).getFullYear()
  }

  get projectName(): string {
    return "Giraffe"
  }

  constructor(
    public gds: GlobalDataService,
  ) {
  }

  ngOnInit(): void {
  }

}
