import {Injectable} from "@angular/core";
import {ApiService, RequestBuilder} from "./http/api.service";
import {Observable} from "rxjs";
import {UserFileResponseDTO} from "../model/http/user.file.dto";

@Injectable({providedIn: "root"})
export class UserFileService {
  constructor(
    private api: ApiService
  ) {
  }

  uploadFile(name: string, description: string, file: File): Observable<void> {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("file", file);
    return this.api.post(
      RequestBuilder.create("/giraffe/user-files/upload")
        .auth()
        .body(formData)
    );
  }

  getFiles(): Observable<UserFileResponseDTO> {
    return this.api.get(
      RequestBuilder.create("/giraffe/user-files")
        .auth()
    );
  }

  deleteFile(id: number): Observable<void> {
    return this.api.delete(
      RequestBuilder.create(`/giraffe/user-files/${id}`)
        .auth()
    );
  }
}
