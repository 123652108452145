import {Component, OnInit} from '@angular/core';
import {PersonalityTestService} from "../../../service/personality-test.service";
import {BehaviourCourseTagDTO, CourseTagDTO, LocationGroupDTO, TestDTO} from "../../../model/http/test.dto";
import {ImageVM} from "../../../model/viewModel/base.vm";
import {MediaContainerDTO, ResponseDTO} from "../../../model/http/base.dto";
import {MediaMapperService} from "../../../mapper/media-mapper.service";
import {zip} from "rxjs";
import {Router} from "@angular/router";
import {CreateTestResultItemDTO} from "../../../model/http/test-result.dto";
import {AuthService} from "../../../service/auth.service";
import {LocaleDTO} from "../../../model/http/global.dto";
import {GlobalDataService} from "../../../service/global-data.service";

export type SelectionVM = {
  type: "rate",
  rating: number;
} | {
  type: "location",
  selected: LocationGroupDTO
} | {
  type: "language",
  selected: LocaleDTO,
};

@Component({
  selector: 'app-personality-test',
  templateUrl: './personality-test.component.html',
  styleUrls: ['./personality-test.component.scss']
})
export class PersonalityTestComponent implements OnInit {
  isLoaded: boolean = true;
  isEnded: boolean = false;
  currentTestItem: TestDTO | undefined;
  private availableTestItems: Array<TestDTO> = [];
  private testItems: Array<TestDTO> = [];
  private selectionArray: Array<SelectionVM> = [];
  courseTags: Array<CourseTagDTO> = [];
  locations: Array<LocationGroupDTO> = [];
  locales: LocaleDTO[];

  constructor(
    private personalityTestService: PersonalityTestService,
    private mediaMapper: MediaMapperService,
    private router: Router,
    private authService: AuthService,
    private globalDataService: GlobalDataService,
  ) {
    this.locales = globalDataService.locales;
  }

  ngOnInit(): void {
    zip(
      this.personalityTestService.getLocations(),
      this.personalityTestService.getCourseTags(),
      this.personalityTestService.getTests()
    ).subscribe(items => {
      this.locations = items[0];
      this.courseTags = items[1];
      this.testItems = items[2];
      this.availableTestItems = [...items[2]];
      this.isLoaded = true
    });
  }

  onStartTestButtonClicked() {
    this.requestNewTestItem();
    return false;
  }

  onRateButtonClicked(rate: number) {
    this.selectionArray.push({
      type: "rate",
      rating: rate
    });
    this.requestNewTestItem();
  }

  onLocationButtonClicked(location: LocationGroupDTO) {
    this.selectionArray.push({
      type: "location",
      selected: location
    });
    this.requestNewTestItem();
    return false;
  }

  onLanguageButtonClicked(locale: LocaleDTO){
    this.selectionArray.push({
      type: "language",
      selected: locale,
    });
    this.requestNewTestItem();
    return false;
  }

  private mapResult(): CreateTestResultItemDTO[] {
    let result: CreateTestResultItemDTO[] = [];
    result.push(
      ...this.selectionArray.filter(item => item.type == 'location').map(item => (<CreateTestResultItemDTO>{
        id: (<any>item).selected.id,
        type: 'location'
      }))
    );
    result.push(
      ...this.selectionArray.filter(item => item.type == 'language').map(item =>  (<CreateTestResultItemDTO>{
        id: 0,
        type: "language",
        selected: (<any>item).selected
      }))
    )
    console.log(result);
    console.log(this.locations);
    console.log(this.selectionArray);
    result.push(
      ...this.courseTags
        .map(item => {
          let [earned_points, max_points] = this.getPercentageFor(item);
          return <CreateTestResultItemDTO>{
            id: item.id,
            type: "rate",
            max_points: max_points,
            earned_points: earned_points,
          }
        })
    );
    return result;
  }

  getPercentageFor(courseTag: CourseTagDTO) : number[] {
    let maxPoint = 0;
    let currentPoint = 0;
    for (let index in this.selectionArray) {
      let selection = this.selectionArray[index];
      let test = this.testItems[index];
      let courseTagDTO = (<BehaviourCourseTagDTO>test.behaviour[0])?.course_tag?.data?.attributes;
      if (selection.type == 'rate' && courseTagDTO && courseTagDTO.name == courseTag.name) {
        maxPoint += 5;
        currentPoint += selection.rating;
      }
    }
    if (currentPoint == 0) {
      return [];
    }
    return [currentPoint, maxPoint];
  }

  private requestNewTestItem() {
    if (this.availableTestItems.length == 0) {
      this.isLoaded = false;
      this.personalityTestService.createTestResult(this.mapResult()).subscribe((response) => {
        if(this.authService.isLoggedIn){
          this.router.navigate(["test-result", response.id]);
        }else{
          this.router.navigate(["login"]);
        }
      });
      this.isEnded = true;
      this.currentTestItem = null;
      return;
    }
    this.currentTestItem = this.availableTestItems[0];
    this.availableTestItems.shift();
  }

  getImage(illustration: ResponseDTO<MediaContainerDTO>): ImageVM | undefined {
    return this.mediaMapper.selectImageFromResponse(illustration);
  }
}
