import {Injectable} from '@angular/core';
import {MediaContainerDTO, MediaDTO, ResponseDTO} from "../model/http/base.dto";
import {environment} from "../../environments/environment";
import {ImageSetVM, ImageVM} from "../model/viewModel/base.vm";

@Injectable({
  providedIn: 'root'
})
export class MediaMapperService {

  constructor() {
  }

  selectImageFromResponse(data: ResponseDTO<MediaContainerDTO>,) : ImageVM | undefined{
    if(!data || !data.data){
      return undefined;
    }
    return this.selectImageFromContainer(data.data.attributes);
  }

  selectImageFromContainer(container: MediaContainerDTO) : ImageVM | undefined{
    let candidates = [
      container?.formats?.thumbnail,
      container?.formats?.small,
      container?.formats?.medium,
      container?.formats?.large,
    ].reverse().filter(item => !!item);
    if(candidates.length == 0){
      return {
        width: container?.width,
        height: container?.height,
        url: environment.baseUrl + container.url,
      };
    }
    return this.mapToImage(candidates[0]);
  }

  mapToImageSet(data: ResponseDTO<MediaContainerDTO>): ImageSetVM {
    return [
      data.data.attributes.formats.thumbnail,
      data.data.attributes.formats.small,
      data.data.attributes.formats.medium,
      data.data.attributes.formats.large,
    ].map(item => this.mapToImage(item))
  }

  mapToImage(data: MediaDTO): ImageVM {
    return {
      width: data.width,
      height: data.height,
      url: environment.baseUrl + data.url,
    };
  }
}
