import {Component, HostListener, OnInit} from '@angular/core';
import {DialogService, DialogButton} from "../../../service/dialog.service";

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
})
export class DialogContainerComponent implements OnInit {
  constructor(
    public alertService: DialogService
  ) {

  }

  ngOnInit(): void {
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(this.alertService.currentDialog?.cancellable){
      this.alertService.dismiss();
    }
  }

  get buttonNegative() : DialogButton | undefined {
    return this.alertService.currentDialog?.buttons?.negative;
  }

  get buttonPositive() : DialogButton | undefined {
    return this.alertService.currentDialog?.buttons?.positive;
  }

  onButtonClicked(button: DialogButton) {
    this.alertService.dismiss();
    button?.callback?.();
  }
}
