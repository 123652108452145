import {Injectable} from '@angular/core';

export type Dialog = {
  title: string;
  content: string;
  type: "info" | "error" | "warning";
  buttons: {
    positive?: DialogButton,
    negative?: DialogButton,
  },
  positiveButton?: DialogButton,
  negativeButton?: DialogButton,
  cancellable?: boolean;
};
export type DialogButton = {
  label: string;
  callback?: () => void;
};

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  public currentDialog: Dialog | null = null;
  private dialogQueue: Dialog[] = [];

  constructor() {
  }

  show(dialog: Dialog) {
    if (this.currentDialog != null) {
      this.dialogQueue.push(dialog);
    } else {
      this.currentDialog = dialog;
    }
  }

  dismiss() {
    let nextDialog: Dialog | undefined = this.dialogQueue.shift();
    if (nextDialog) {
      this.currentDialog = nextDialog;
    } else {
      this.currentDialog = null;
    }
  }
}
