import {Injectable} from '@angular/core';
import {ApiService, RequestBuilder} from "./http/api.service";
import {ResponseDTO} from "../model/http/base.dto";
import {map, Observable} from "rxjs";
import {LegalDTO} from "../model/http/legal.dto";
import {LegalVM} from "../model/viewModel/legal.vm";

@Injectable({
  providedIn: 'root'
})
export class LegalService {

  constructor(
    private api: ApiService
  ) {
  }

  getTermsOfConditions(): Observable<LegalVM> {
    return this.api.get<ResponseDTO<LegalDTO>>(RequestBuilder.create("/toc"))
      .pipe(
        map(response => {
          return response.data.attributes
        })
      );
  }

  getPrivacyPolicy(): Observable<LegalVM> {
    return this.api.get<ResponseDTO<LegalDTO>>(RequestBuilder.create("/pp"))
      .pipe(
        map(response => {
          return response.data.attributes
        })
      );
  }

}
