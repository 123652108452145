<div class="card test-result-card">
  <div class="card-header">{{ "Test results" | trans:  'userprofile.test_result_header' }}</div>
  <div class="card-body" *ngIf="!isLoading">
    <div class="test-result-container">
      <div class="test-result" *ngFor="let tr of data.slice(0, 4)" (click)="onTestResultClicked(tr)">
        <div class="test-result-overlay"></div>
        <div class="test-result-inner">
          <img src="{{ getIconUrl(tr.best_course_tag)}}" class="icon d-block mx-auto">
          <div class="course-tag-name text-center pt-2">{{ tr.best_course_tag.name }}</div>
          <div class="delimiter w-25 p-3"></div>
          <div class="discipline">{{ tr.best_course_discipline.name }}</div>
        </div>
        <div class="lock" *ngIf="!tr.unlocked">
          <img src="assets/ic_lock.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
</div>
