import {Injectable, Injector} from '@angular/core';
import {
  catchError,
  map,
  mergeMap,
  never,
  Observable,
  ObservableInput,
  ObservedValueOf,
  of,
  OperatorFunction,
  tap
} from "rxjs";
import {StorageService} from "./storage.service";
import {LoginResponseDTO, UserDTO} from "../model/http/auth.dto";
import {ApiService, RequestBuilder} from "./http/api.service";
import {PersonalityTestService} from "./personality-test.service";

export type LoginResult = {
  target: "main" | "test-result";
  targetId?: number;
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: UserDTO | undefined;

  constructor(
    private api: ApiService,
    private injector: Injector,
    private storage: StorageService
  ) {
  }

  get isLoggedIn(): boolean {
    return !!this.storage.authToken
  }

  updateUserInfo(): Observable<void> {
    return this.api.get<UserDTO>(
      RequestBuilder.create("/users/me")
        .auth()
    ).pipe(
      tap({
        next: (data) => {
          this.currentUser = data;
        }
      }),
      this.createTokenErrorHandler(),
      map(() => null)
    )
  }

  login(email: string, password: string): Observable<LoginResult> {
    return this.api.post<LoginResponseDTO>(
      RequestBuilder.create("/auth/local")
        .body({
          identifier: email,
          password: password
        })
    ).pipe(
      tap({
        next: (response: LoginResponseDTO) => {
          this.storage.authToken = response.jwt;
          this.currentUser = response.user;
        }
      }),
      mergeMap(() => {
        if (this.storage.pendingTestResult) {
          return this.injector.get(PersonalityTestService).attachToUser(this.storage.pendingTestResult)
            .pipe(map(() => {
              let pendingId = this.storage.pendingTestResult;
              this.storage.pendingTestResult = null;
              return <LoginResult>{
                target: "test-result",
                targetId: pendingId
              }
            }));
        } else {
          return of(<LoginResult>{
            target: "main"
          })
        }
      }),
    )
  }

  register(displayName: string, email: string, password: string): Observable<null> {
    return this.api.post(
      RequestBuilder.create("/auth/local/register")
        .body({
          username: displayName,
          email: email,
          password: password,
        })
    );
  }


  forgottPassword(email: string): Observable<void> {
    return this.api.post(
      RequestBuilder.create("/auth/forgot-password")
        .body(
          {
            email
          }
        )
    );
  }

  resetPassword(code: string, password: string): Observable<any> {
    return this.api.post(
      RequestBuilder.create("/auth/reset-password")
        .body(
          {
            code,
            password,
            passwordConfirmation: password
          }
        )
    );
  }

  changePassword(currentPassword: string, password: string, passwordConfirmation: string) {
    return this.api.post(
      RequestBuilder
        .create("/auth/change-password")
        .auth()
        .body({
          currentPassword,
          password,
          passwordConfirmation,
        })
    ).pipe(this.createTokenErrorHandler());
  }

  logout() {
    this.storage.authToken = null;
  }

  createTokenErrorHandler<T, O extends ObservableInput<any>>(): OperatorFunction<T, T | ObservedValueOf<O>> {
    return catchError(error => {
      if (error.status === 401) {
        this.logout();
        window.location.reload();
      }
      return <ObservedValueOf<O>>null;
    });
  }
}
