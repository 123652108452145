<div class="col-md-12 row">
  <div class="col-12" *ngIf="subjects.length > 1">
    <label class="secondary">{{ label }}</label>
  </div>
  <div class="col-6 label-column" *ngIf="subjects.length > 1">
    <select (change)="onSubjectChanged($event)">
      <option>{{ "Choose a subject" | trans: 'calculator.performance_input_hint' }}</option>
      <option *ngFor="let subject of subjects" value="{{ subject.id }}">{{ subject.name }}</option>
    </select>
  </div>
  <div class="col-6" *ngIf="subjects.length == 1">
    <label class="primary">{{ label }}</label>
  </div>
  <div class="col-6 text-end">
    <input type="text" [attr.maxlength]="maxLength" (change)="onValueChanged($event)">
  </div>
  <div class="col-12" *ngIf="levelDecoration">
    <div class="level-selector">
      <label class="form-input-checkbox">
        {{ "Advanced exam" | trans: 'calculator.performance_input_advanced_label' }}
        <input type="checkbox" (change)="onLevelChanged($event)">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</div>
