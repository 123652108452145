import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from "./component/page/main/main.component";
import {ArticleComponent} from "./component/page/article/article.component";
import {ServicesComponent} from "./component/page/services/services.component";
import {LegalComponent, TYPE_PRIVACY_POLICY, TYPE_TERMS_OF_CONDITIONS} from "./component/page/legal/legal.component";
import {LoginComponent} from "./component/page/login/login.component";
import {ErrorComponent} from './component/page/error/error.component';
import {RegisterComponent} from './component/page/register/register.component';
import {ForgottPasswordComponent} from './component/page/forgott-password/forgott-password.component';
import {ResetPasswordComponent} from './component/page/reset-password/reset-password.component';
import {PersonalityTestComponent} from "./component/page/personality-test/personality-test.component";
import {PaymentComponent} from "./component/page/payment/payment.component";
import {
  PersonalityTestResultComponent
} from "./component/page/personality-test-result/personality-test-result.component";
import {MockPaymentComponent} from "./component/page/mock-payment/mock-payment.component";
import {CourseDetailsComponent} from "./component/page/course-details/course-details.component";
import {UserSettingsComponent} from "./component/page/user-settings/user-settings.component";
import {AuthenticatedGuard} from "./guard/authenticated.guard";
import {AnonymousGuard} from "./guard/anonymous.guard";
import {UserProfileComponent} from "./component/page/user-profile/user-profile.component";
import { CalculatorComponent } from "./component/page/calculator/calculator.component"

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
  },
  {
    path: "articles/:id",
    component: ArticleComponent,
    pathMatch: "full"
  },
  {
    path: "services",
    component: ServicesComponent,
    pathMatch: "full"
  },
  {
    path: "terms-of-conditions",
    component: LegalComponent,
    pathMatch: "full",
    data: {
      type: TYPE_TERMS_OF_CONDITIONS
    }
  },
  {
    path: "privacy-policy",
    component: LegalComponent,
    pathMatch: "full",
    data: {
      type: TYPE_PRIVACY_POLICY
    }
  },
  {
    path: "login",
    component: LoginComponent,
    pathMatch: "full",
    canActivate: [
      AnonymousGuard
    ]
  },
  {
    path: "register",
    component: RegisterComponent,
    pathMatch: "full",
    canActivate: [
      AnonymousGuard
    ]
  },
  {
    path: "forgott-password",
    component: ForgottPasswordComponent,
    pathMatch: "full",
    canActivate: [
      AnonymousGuard
    ]
  },
  {
    path: "reset-password/:code",
    component: ResetPasswordComponent,
    pathMatch: "full",
    canActivate: [
      AnonymousGuard
    ]
  },
  {
    path: "test",
    component: PersonalityTestComponent,
    pathMatch: "full"
  },
  {
    path: "error/:code",
    component: ErrorComponent,
    pathMatch: "full",
  },
  {
    path: "calculator",
    component: CalculatorComponent
  },
  {
    path: "payment/:transId",
    component: PaymentComponent,
    pathMatch: "full",
    canActivate: [
      AuthenticatedGuard
    ]
  },
  {
    path: "course/:id",
    component: CourseDetailsComponent,
    pathMatch: "full",
    canActivate: [
      AuthenticatedGuard
    ]
  },
  {
    path: "payment/:transId/:result",
    component: PaymentComponent,
    pathMatch: "full",
    canActivate: [
      AuthenticatedGuard
    ]
  },
  {
    path: "test-result/:id",
    component: PersonalityTestResultComponent,
    pathMatch: "full",
    canActivate: [
      AuthenticatedGuard
    ]
  },
  {
    path: "user/settings",
    component: UserSettingsComponent,
    pathMatch: "full",
    canActivate: [
      AuthenticatedGuard
    ]
  },
  {
    path: "user/profile",
    component: UserProfileComponent,
    pathMatch: "full",
    canActivate: [
      AuthenticatedGuard
    ]
  },
  {
    path: "**",
    redirectTo: "/error/404",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
