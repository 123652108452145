import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


type TranslationResponseDTO = {
  main: {
    header_cta: string;
    about_title: string;
  }
};
// type Flatten<T, Path extends string = ''> =
//   T extends object
//     ? {
//       [K in keyof T as `${Path}${Path extends '' ? '' : '_'}${K extends string ? K : ''}`]: Flatten<T[K], K>
//     }[keyof T]
//     : T

type Source = {
  main: { header: string, cta: string};
  about: { header: string }
};


type Flatten<T> = {
  [K in keyof T as `${string & K}.${string & keyof T[K]}`]: T[K][keyof T[K]]
};
type FlattenedSource = Flatten<TranslationResponseDTO>;
let a: FlattenedSource;

