import {Injectable} from "@angular/core";
import {ApiService, RequestBuilder} from "./http/api.service";
import {ListResponseDTO, ResponseDTO} from "../model/http/base.dto";
import {map, Observable} from "rxjs";
import { CourseDTO} from "../model/http/course.dto";
import {HighSchoolSubjectVM} from "../model/viewModel/calculator.vm";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class CourseService{
  constructor(
    private api: ApiService,
    private authService: AuthService,
  ){}

  getCourses() : Observable<ListResponseDTO<CourseDTO>>{
    return this.api.get<ListResponseDTO<CourseDTO>>(
      RequestBuilder.create(`/courses`).locale().auth()
    ).pipe(this.authService.createTokenErrorHandler());
  }

  getCourse(id: number) : Observable<ResponseDTO<CourseDTO>>{
    return this.api.get<ResponseDTO<CourseDTO>>(
      RequestBuilder.create(`/courses/${id}`).locale().auth()
    ).pipe(this.authService.createTokenErrorHandler());
  }


}
