<div class="container-news">
  <div class="container">
    <h2>{{ 'News' | trans: 'main.news_title' }}</h2>
    <app-loading *ngIf="!articles"></app-loading>
    <div class="items" *ngIf="articles">
      <div class="news" *ngFor="let article of articles">
        <img *ngIf="article.cover" src="{{article.cover.url}}">
        <img *ngIf="!article.cover" src="assets/bg_no_image.svg">
        <h3>{{ article.title }}</h3>
        <p>{{ article.excerpt }}</p>
        <a routerLink="/articles/{{article.id}}">{{ "Read more" | trans: 'main.news_cta' }}</a>
      </div>
    </div>
  </div>
</div>
