<div class="card info p-2 mb-4">
  <div class="card-body">
    <h2>{{ "Calculator" | trans: 'calculator.header' }}</h2>
    <div class="content-row">
      {{
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id augue et nulla venenatis tristique vitae\n" +
        "      a turpis. Integer a erat sapien. Vestibulum elementum eros volutpat venenatis convallis. In nec vestibulum\n" +
        "      nunc, eget dapibus orci. Curabitur lectus nulla, bibendum eu felis sed, auctor tincidunt massa. Aliquam erat\n" +
        "      volutpat. Sed tristique nec dui et viverra. Duis vitae nisl imperdiet, maximus tortor in, sagittis neque.\n" +
        "      Nunc sollicitudin dictum pharetra." | trans: 'calculator.info'
      }}
    </div>
  </div>
</div>
