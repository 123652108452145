<div class="card course-selector p-2 mb-4">
  <div class="card-body">
    <h3>{{ "Course selector" | trans: 'calculator.course_selector_title' }}</h3>
    <app-loading *ngIf="isLoading"></app-loading>
    <div class="content-row row" *ngIf="!isLoading">
      <div class="col-md-6">
        <label>{{ "Course" | trans: 'calculator.course_selector_course_title' }}</label>
        <select [disabled]="!isInActiveState" (change)="onCourseChanged($event)">
          <option value="-1">{{ "Select one" | trans: 'calculator.course_selector_empty_input' }}</option>
          <option *ngFor="let course of courses; index as i" value="{{i}}">{{ course.name }}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label>{{ "University" | trans: 'calculator.course_selector_university_title' }}</label>
        <select (change)="onUniversityChanged($event)" [disabled]="universities.length == 0 || !isInActiveState">
          <option value="-1">{{ "Select one" | trans: 'calculator.course_selector_empty_input' }}</option>
          <option *ngFor="let university of universities" value="{{ university.id }}">{{ university.name }}</option>
        </select>
      </div>
    </div>
    <div class="footer-row mt-4 text-center" *ngIf="isInActiveState">
      <button class="btn btn-primary" [disabled]="!isValid()" (click)="onNextButtonClicked()">{{ "Next" | trans: 'calculator.button_next' }}</button>
    </div>
  </div>
</div>
