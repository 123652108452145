import {Component, OnInit} from '@angular/core';
import {OrderService} from "../../../../service/order.service";
import {TransactionDTO} from "../../../../model/http/order.dto";
import {TransactionVM} from "../../../../model/viewModel/order.vm";

type State = "loading" | "loaded" | "error";

@Component({
  selector: 'app-user-profile-transactions',
  templateUrl: './user-profile-transactions.component.html',
  styleUrls: ['./user-profile-transactions.component.scss']
})
export class UserProfileTransactionsComponent implements OnInit {
  state: State = "loading";
  transactions: TransactionVM[] = [];

  constructor(
    private orderService: OrderService,
  ) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  onRetryButtonClicked() {
    this.loadData();
  }

  private loadData() {
    this.state = "loading";
    this.orderService.getTransactions()
      .subscribe({
        next: (transactions) => {
          this.transactions = transactions.slice(0, 3).map(dto => {
            return {
              state: dto.state,
              paymentMethod: dto.paymentMethod,
              name: dto.service?.name || dto.plan?.name,
              price: dto.service?.price || dto.plan?.price,
              type: (!!dto.service) ? "service" : "plan",
              createdAt: new Date(dto.createdAt)
            };
          });
          this.state = "loaded";
        },
        error: (error) => {
          this.state = "error";
        }
      });
  }
}
