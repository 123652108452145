import {Injectable} from '@angular/core';
import {ApiService, RequestBuilder} from "./http/api.service";
import {MediaMapperService} from "../mapper/media-mapper.service";
import {AttributesDTO, ListResponseDTO} from "../model/http/base.dto";
import {ServiceDTO} from "../model/http/services.dto";
import {map, Observable} from "rxjs";
import {ServiceVM} from "../model/viewModel/service.vm";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(
    private api: ApiService,
    private mediaMapper: MediaMapperService
  ) {
  }


  getServices(): Observable<ServiceVM[]> {
    return this.api.get<ListResponseDTO<ServiceDTO>>(RequestBuilder.create("/services").locale()).pipe(
      map(response => {
        return response.data
          .filter(dto => {
            return !dto.attributes.feature_related
          })
          .map(dto => {
            return this.mapServiceDto(dto);
          });
      })
    )
  }

  findTestUnlockService() : Observable<ServiceVM | undefined>{
    return this.api.get<ListResponseDTO<ServiceDTO>>(RequestBuilder.create("/services").locale()).pipe(map(response => {
      return response.data.map(item => this.mapServiceDto(item)).find((item) => {
        return item.feature_trigger == "test_unlock";
      });
    }));
  }

  private mapServiceDto(dto: AttributesDTO<ServiceDTO>) : ServiceVM {
    return <ServiceVM>{
      id: dto.id,
      name: dto.attributes.name,
      price: dto.attributes.price,
      description: dto.attributes.description,
      image: this.mediaMapper.selectImageFromResponse(dto?.attributes?.image),
      feature_trigger: dto.attributes.feature_trigger,
    };
  }


}
