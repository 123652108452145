import {Component, Input, OnInit} from '@angular/core';
import {Model} from "../calculator.component";
import {PerformanceEntry} from "../calculator-card-performance-input/calculator-card-performance-input.component";
import {TranslationService} from "../../../../service/translation.service";

@Component({
  selector: 'app-calculator-card-estimation',
  templateUrl: './calculator-card-estimation.component.html',
  styleUrls: ['./calculator-card-estimation.component.scss']
})
export class CalculatorCardEstimationComponent implements OnInit {
  @Input() model: Model
  selectedCalculationMethod: "study" | "grad";
  calculatedStudyPoints: number;
  calculatedGradPoints: number;
  calculatedInstitutePoints: number;
  calculatedSummaryPoints: number;

  constructor(
    private translationService: TranslationService,
  ) {
  }

  ngOnInit(): void {
    this.calculatedStudyPoints = this.calculateStudyPoints();
    this.calculatedGradPoints = this.calculateGraduationPoints();
    this.calculatedInstitutePoints = this.calculateInstitutePoints();
    let studyCandidate = this.calculatedStudyPoints + this.calculatedGradPoints;
    let gradCandidate = this.calculatedGradPoints * 2;
    if (isNaN(studyCandidate) || studyCandidate < gradCandidate) {
      this.selectedCalculationMethod = "grad";
      this.calculatedSummaryPoints = gradCandidate;
    } else {
      this.selectedCalculationMethod = "study";
      this.calculatedSummaryPoints = studyCandidate;
    }
  }

  private calculateStudyPoints(): number {
    let course = this.model.courseSelectionResult;
    debugger;
    if (!course) return NaN;
    let lastYearPerformance = Object.values(this.model.performanceInputResult.lastYearResult);
    let gradPerformance = Object.values(this.model.performanceInputResult.graduationResult);
    if (!course.study_last_year_req.some(reqSubject => lastYearPerformance.some(lyp => lyp.subject.id == reqSubject.id))) return NaN;
    if (!course.study_grad_req.some(reqSubject => gradPerformance.some(gp => gp.subject.id == reqSubject.id))) return NaN;
    let studyPoints = lastYearPerformance.reduce((acc, item) => item.value * 4 + acc, 0);
    let gradPoints = gradPerformance.reduce((acc , item) => item.value + acc, 0) / gradPerformance.length;
    return Math.floor(studyPoints + gradPoints);
  }

  private calculateGraduationPoints(): number {
    let course = this.model.courseSelectionResult;
    debugger;
    if (!course) return NaN;
    let gradPerformance = Object.values(this.model.performanceInputResult.graduationResult)
      .sort((left, right) => right.value - left.value);
    let firstReq = course.grad_req1;
    let secondReq = course.grad_req2;
    let firstPerformance = gradPerformance.find(item => firstReq.some(req => req.id == item.subject.id));
    if (!firstPerformance) return NaN;
    let secondPerformance = gradPerformance
      .find(item =>
        secondReq
          .some(req => req.id == item.subject.id) && item.subject.id != firstPerformance.subject.id
      );
    if (!secondPerformance) return NaN;

    const toPoints = (performance: PerformanceEntry) => performance.value * (performance.isAdvanced ? 1 : 0.67);
    return Math.floor(toPoints(firstPerformance) + toPoints(secondPerformance));
  }

  private calculateInstitutePoints(): number {
    //TODO: Implement
    return 0;
  }

  getPreferredCalculationMethod() {
    if(this.selectedCalculationMethod == "grad"){
      return this.translationService.trans("calculator.estimation_pref_calc_method_grad", "Graduation result duplication");
    }else{
      return this.translationService.trans("calculator.estimation_pref_calc_method_study", "Study points");
    }
  }
}
