import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {ApiService, RequestBuilder} from "./http/api.service";
import {
  CreateServiceOrderResponseDTO,
  TransactionDTO,
  UpdatePayPalServiceOrderResponseDTO,
  UpdateServiceOrderDTO
} from "../model/http/order.dto";
import {FinalizePaymentResponseDTO} from "../model/http/payment.dto";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(
    private api: ApiService,
    private authService: AuthService,
  ) {
  }

  getTransactions(): Observable<TransactionDTO[]> {
    return this.api.get(
      RequestBuilder.create(`/order/transactions`).auth().locale()
    ).pipe(this.authService.createTokenErrorHandler());
  }

  createPlanOrder(planId: number): Observable<number> {
    return this.api.post<CreateServiceOrderResponseDTO>(
      RequestBuilder
        .create(`/order/create/plan/${planId}`)
        .auth()
    ).pipe(
      map(item => item.id),
      this.authService.createTokenErrorHandler()
    )
  }

  createServiceOrder(serviceId: number, targetId?: number): Observable<number> {
    let $source: Observable<CreateServiceOrderResponseDTO> = null;
    if (!targetId) {
      $source = this.api.post<CreateServiceOrderResponseDTO>(
        RequestBuilder
          .create(`/order/create/service/${serviceId}`)
          .auth()
      ).pipe(this.authService.createTokenErrorHandler())
    } else {
      $source = this.api.post<CreateServiceOrderResponseDTO>(
        RequestBuilder
          .create(`/order/create/service/${serviceId}/${targetId}`)
          .auth()
      ).pipe(this.authService.createTokenErrorHandler())
    }
    return $source.pipe(map(item => item.id))
  }

  updatePayPalOrder(id: number, firstName: string, lastName: string, billingCountry: string, billingCity: string, billingZipCode: string, billingStreet: string): Observable<string> {
    return this.api.post<UpdatePayPalServiceOrderResponseDTO>(
      RequestBuilder.create(`/order/update/paypal/${id}`)
        .auth()
        .body(<UpdateServiceOrderDTO>{
          firstName, lastName, billingCountry, billingZipCode, billingCity, billingStreet
        })
    ).pipe(
      map(response => {
        return response.redirectUrl;
      }),
      this.authService.createTokenErrorHandler()
    );
  }

  finalizePayPalOrder(id: string, payerId: string, paymentId: string): Observable<FinalizePaymentResponseDTO> {
    return this.api.post(
      RequestBuilder.create(`/order/finalize-order/paypal/${id}`)
        .auth()
        .body({
          payerId, paymentId
        })
    ).pipe(this.authService.createTokenErrorHandler());
  }


}
