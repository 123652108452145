<app-menu currentMenu="main"></app-menu>
<div class="page page-login">
  <div class="card-login">
    <form (ngSubmit)="onLoginFormSubmitted()" [formGroup]="loginForm">
      <img src="assets/menu_logo.svg" class="logo">
      <h1>{{"Sign in" | trans:"login.header"}}</h1>
      <div class="input-container">
        <label>{{"E-mail" | trans:"login.email_label"}}</label>
        <div class="input">
          <img src="assets/ic_email.svg">
          <input type="email" placeholder="{{'example@gmail.com' | trans:'login.email_placeholder'}}" id="email"
            formControlName="email">
        </div>
        <div *ngIf="submitted && email?.invalid && (email?.dirty || email?.touched)" class="error-container">

          <div *ngIf="email?.errors?.['required']">
            {{ "E-mail field is required" | trans:"login.email_error_required" }}
          </div>
          <div *ngIf="email?.errors?.['email']">
            {{ "Invalid format" | trans:"login.email_error_invalid_email" }}
          </div>
        </div>
      </div>

      <div class="input-container">
        <label>{{ "Password" | trans:'login.password_label' }}</label>
        <div class="input">
          <img src="assets/ic_password.svg">
          <input type="password" placeholder="{{ '*****' | trans:'login.password_placeholder' }}" name="password"
            formControlName="password">
        </div>
        <div *ngIf="submitted && password?.invalid && (password?.dirty || password?.touched)" class="error-container">

          <div *ngIf="password?.errors?.['required']">
            {{"Password is required" | trans:'login.password_error_required'}}
          </div>
        </div>
      </div>
      <div class="reset-container">
        <a routerLink="/password-reset">{{ "Forgott password" | trans:'login.password_reset_link' }}</a>
      </div>
      <button class="login-button">{{ "Sign in" | trans:'login.submit_button' }}</button>
      <!--      <div class="login-sso-button" (click)="onSSOLoginClicked()">-->
      <!--        <img src="assets/ic_google_mono.svg">-->
      <!--        Sign in with Google-->
      <!--      </div>-->
    </form>
    <div class="register-button">
      <a routerLink="/register">{{ "Sign up" | trans:'login.register_button' }}</a>
    </div>
  </div>
</div>
<app-footer></app-footer>
