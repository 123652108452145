import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../service/auth.service";
import {DialogService} from "../../../service/dialog.service";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  isLoading = false;


  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
  }

}
