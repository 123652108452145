import {Injectable} from '@angular/core';
import {ApiService, RequestBuilder} from "./http/api.service";
import {PlanVM} from "../model/viewModel/plan.vm";
import {map, Observable} from "rxjs";
import {MediaMapperService} from "../mapper/media-mapper.service";
import {ListResponseDTO} from "../model/http/base.dto";
import {PlanDTO} from "../model/http/services.dto";

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(
    private api: ApiService,
    private mediaMapper: MediaMapperService
  ) { }

  getPlans() : Observable<PlanVM[]>{
    return this.api.get<ListResponseDTO<PlanDTO>>(RequestBuilder.create("/plans").locale())
      .pipe(
        map(dto => {
          return dto.data.map(item => {
            return <PlanVM>{
              id: item.id,
              name: item.attributes.name,
              price: item.attributes.price,
              benefits: item.attributes.plan_benefits.data.map(benefitDTO => {
                return {
                  name: benefitDTO.attributes.display_name,
                  description: benefitDTO.attributes.description,
                  icon: this.mediaMapper.selectImageFromResponse(benefitDTO.attributes.icon)
                };
              })
            };
          })
        })
      )
  }
}
