<app-menu></app-menu>
<div class="page page-payment">
  <div class="payment-info-card" *ngIf="!isSuccess">
    <h2>Payment information</h2>
    <form (ngSubmit)="onPaymentFormSubmitted()" [formGroup]="paymentForm">

      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="form-control">
              <label>{{ "First name" | trans: 'payment.field_first_name_label' }}</label>
              <input type="text" placeholder="{{ 'Elek' | trans: 'payment.field_first_name_placeholder' }}"
                     formControlName="firstName">
              <div *ngIf="isInvalid('firstName')" class="error-container">
                <div *ngIf="isRequired('firstName')">
                  {{ "This field is required" | trans:"payment.error_field_required" }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-control col col-sm-6">
              <label>{{ "Last name" | trans: 'payment.field_last_name_label' }}</label>
              <input type="text" placeholder="{{ 'Test' | trans: 'payment.field_last_name_placeholder' }}"
                     formControlName="lastName">
              <div *ngIf="isInvalid('lastName')" class="error-container">

                <div *ngIf="isRequired('lastName')">
                  {{ "This field is required" | trans:"payment.error_field_required" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <h3>Billing address</h3>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="form-control">
              <label>{{ "Country" | trans: 'payment.field_country_name_label' }}</label>
              <input type="text" placeholder="{{ 'Hungary' | trans: 'payment.field_country_name_placeholder' }}"
                     formControlName="country">
              <div *ngIf="isInvalid('country')" class="error-container">

                <div *ngIf="isRequired('country')">
                  {{ "This field is required" | trans:"payment.error_field_required" }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-control">
              <label>{{ "City" | trans: 'payment.field_city_name_label' }}</label>
              <input type="text" placeholder="{{ 'Budapest' | trans: 'payment.field_city_name_placeholder' }}"
                     formControlName="city">
              <div *ngIf="isInvalid('city')" class="error-container">

                <div *ngIf="isRequired('city')">
                  {{ "This field is required" | trans:"payment.error_field_required" }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-control">
              <label>{{ "ZIP Code" | trans: 'payment.field_zip_code_label' }}</label>
              <input type="text" placeholder="{{ 'XXXX' | trans: 'payment.field_zip_code_placeholder' }}"
                     formControlName="zipCode">
              <div *ngIf="isInvalid('zipCode')" class="error-container">

                <div *ngIf="isRequired('zipCode')">
                  {{ "This field is required" | trans:"payment.error_field_required" }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="form-control">
              <label>{{ "Street" | trans: 'payment.field_street_label' }}</label>
              <input type="text" placeholder="{{ 'Main st. 15.' | trans: 'payment.field_street_placeholder' }}"
                     formControlName="street">
              <div *ngIf="isInvalid('street')" class="error-container">

                <div *ngIf="isRequired('street')">
                  {{ "This field is required" | trans:"payment.error_field_required" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="form-control payment-method-selector">
              <label>{{ "Payment method" | trans: 'payment.field_method_label' }}</label>
              <div class="container">
                <div class="row">
                  <div class="col-6 payment-method" [class.selected]="paymentMethod.value == 'paypal'"
                       (click)="onSelectPaymentMethodClicked('paypal')">
                    <img src="assets/ic_payment_method_paypal.svg">
                    <div>{{ "Online payment with PayPal" | trans: 'payment.field_method_paypal' }}</div>
                  </div>
                  <div class="col-6 payment-method" [class.selected]="paymentMethod.value == 'simplepay'"
                       (click)="onSelectPaymentMethodClicked('simplepay')">
                    <img src="assets/ic_payment_method_simple.svg">
                    <div>{{ "Online payment with SimplePay" | trans: 'payment.field_method_simple' }}</div>
                  </div>
                </div>
              </div>
              <div *ngIf="isInvalid('paymentMethod')" class="error-container">
                <div *ngIf="isRequired('paymentMethod')">
                  {{ "This field is required" | trans:"payment.error_field_required" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <input (click)="onPaymentFormSubmitted()" type="submit" value="{{ 'Submit' | trans: 'payment.submit_button' }}">
        </div>
      </div>

    </form>
  </div>


  <div class="payment-info-card" *ngIf="isSuccess">
    <h2>{{ "Transaction successful" | trans: 'payment.payment_result_success' }}</h2>
  </div>
</div>
<app-footer></app-footer>
