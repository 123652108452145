<div class="card user-file-card mt-4">
  <div class="card-body" *ngIf="!isLoading">
    <h3 class="card-title mb-4">{{ "Files" | trans: "userprofile_userFiles.header" }}</h3>

    <div class="file-upload-container" *ngIf="!isFileFormLoading">
      <h5 class="mt-2">{{ "Upload a file" | trans: "userprofile_userFiles.form_header" }}</h5>
      <form [formGroup]="uploadForm" (submit)="onUploadSubmit()">
        <div class="name-input form-group">
          <label>{{ "Title" | trans: "userprofile_userFiles.form_field_title_label" }}</label>
          <input type="text" class="form-control"
                 placeholder="{{'Test File' | trans: 'userprofile_userFiles.form_field_title_hint'}}" name="name"
                 formControlName="name">
          <div *ngIf="submitted && nameField?.invalid && (nameField?.dirty || nameField?.touched)"
               class="error-container">

            <div *ngIf="nameField?.errors?.['required']">
              {{ "Name field is required" | trans: 'userprofile_userFiles.form_field_title_error_required' }}
            </div>
          </div>
        </div>

        <div class="description-input form-group">
          <label>{{ "Description" | trans: "userprofile_userFiles.form_field_description_label" }}</label>
          <textarea class="form-control"
                    placeholder="{{'Description' | trans: 'userprofile_userFiles.form_field_description_hint'}}"
                    name="description"
                    formControlName="description"></textarea>
          <div *ngIf="submitted && descriptionField?.invalid && (descriptionField?.dirty || descriptionField?.touched)"
               class="error-container">

            <div *ngIf="descriptionField?.errors?.['required']">
              {{ "Description field is required" | trans: 'userprofile_userFiles.form_field_description_error_required' }}
            </div>
          </div>
        </div>

        <div class="file-input input-container">
          <label>{{ "File" | trans: "userprofile_userFiles.form_field_file_label" }}</label>
          <div class="input">
            <input #fileInput type="file" name="file" (change)="onFileChanged($event)">
            <div class="empty-file-input file-display" *ngIf="!fileField.value">
              <img src="assets/ic_plus.svg">
              <div>{{ "Select file" | trans: "userprofile_userFiles.form_field_file_empty" }}</div>
            </div>

            <div class="value-file-input file-display" *ngIf="fileField.value">
              <div><strong>{{ "Name" | trans: "userprofile_userFiles.form_field_file_selected_name" }}
                : </strong> {{ fileField.value.name }}
              </div>
            </div>
          </div>
          <div *ngIf="submitted && fileField?.invalid && (fileField?.dirty || fileField?.touched)"
               class="error-container">

            <div *ngIf="fileField?.errors?.['required']">
              {{ "File is required" | trans: "userprofile_userFiles.form_field_file_error_required" }}
            </div>
          </div>
        </div>

        <div class="submit-input input-container text-center pt-2">
          <input type="submit" class="btn btn-secondary"
                 value="{{'Upload' | trans: 'userprofile_userFiles.form_submit_button'}}">
        </div>
      </form>
    </div>


    <table class="table table-bordered file-container w-100" *ngIf="files.length > 0">
      <thead class="thead-dark">
      <tr>
        <th scope="col">{{ "Title" | trans: 'userprofile_userFiles.list_header_title' }}</th>
        <th scope="col">{{ "Description" | trans: 'userprofile_userFiles.list_header_description' }}</th>
        <th scope="col">{{ "Actions" | trans: 'userprofile_userFiles.list_header_actions' }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let file of files">
        <td>{{ file.title }}</td>
        <td>{{ file.description }}</td>
        <td>
          <button class="btn btn-dark"
                  (click)="onDeleteFileClicked(file)">{{ "Delete" | trans: 'userprofile_userFiles.list_action_delete' }}
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-body" *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
</div>
