import { Component, OnInit } from '@angular/core';
import {MainVM} from "../../../model/viewModel/main.vm";
import {MainDataService} from "../../../service/main-data.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  data: MainVM | null = null
  constructor(
    private mainService: MainDataService
  ) { }

  ngOnInit(): void {
    this.mainService.getData()
      .subscribe(data => {
        console.log(data);
        this.data = data;
      });
  }

}
