import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GlobalDataService} from "../../../../service/global-data.service";
import {AuthService} from "../../../../service/auth.service";
import {DialogService} from "../../../../service/dialog.service";

@Component({
  selector: 'app-user-settings-password',
  templateUrl: './user-settings-password.component.html',
  styleUrls: ['./user-settings-password.component.scss']
})
export class UserSettingsPasswordComponent implements OnInit {
  isLoading: boolean = false;
  submitted: boolean = false;
  invalidPasswordError: boolean = false;
  passwordChangeForm = new FormGroup({
    currentPassword: new FormControl("", [
      Validators.required
    ]),
    newPassword1: new FormControl("", [
      Validators.required
    ]),
    newPassword2: new FormControl("", [
      Validators.required
    ])
  })

  get currentPassword() {
    return this.passwordChangeForm.get("currentPassword");
  }

  get newPassword1() {
    return this.passwordChangeForm.get("newPassword1");
  }

  get newPassword2() {
    return this.passwordChangeForm.get("newPassword2");
  }


  constructor(
    private gds: GlobalDataService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
  }


  onPasswordChangeSubmitted() {
    console.log("Submitted: ", this.passwordChangeForm.invalid);
    this.submitted = true;
    this.passwordChangeForm.markAllAsTouched()
    if (this.passwordChangeForm.invalid) {
      return;
    }
    let model = this.passwordChangeForm.value;
    this.isLoading = true;
    this.authService.changePassword(model.currentPassword, model.newPassword1, model.newPassword2)
      .subscribe({
        next: () => {
          this.dialogService.show({
            title: this.gds.translations["usersettings"]["password_change_success_title"],
            content: this.gds.translations["usersettings"]["password_change_success_content"],
            buttons: {
              positive: {
                label: this.gds.translations["usersettings"]["password_change_success_button"]
              }
            },
            type: "info"
          });
          this.passwordChangeForm.setValue({
            currentPassword: "",
            newPassword1: "",
            newPassword2: ""
          });
          this.submitted = false;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          this.invalidPasswordError = true;
        }
      });
  }

}
