import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TestCourseDTO} from "../../../model/http/test-result.dto";
import {CourseService} from "../../../service/course.service";
import {mergeMap, retry, switchMap} from "rxjs";
import {MediaContainerDTO, ResponseDTO} from "../../../model/http/base.dto";
import {ImageVM} from "../../../model/viewModel/base.vm";
import {MediaMapperService} from "../../../mapper/media-mapper.service";
import {CourseDTO} from "../../../model/http/course.dto";

@Component({
  selector: 'app-mock-payment',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {
  private transId: number;
  private secret: string;
  isLoaded: boolean = false;
  data: CourseDTO;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private mediaService: MediaMapperService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      switchMap(params => {
        return this.courseService.getCourse(params["id"])
      }),
      retry({
        delay: 5000
      })
    ).subscribe(params => {
      this.data = params.data.attributes;
      this.isLoaded = true;
    });
  }

  onRejectButtonClicked() {
    this.router.navigate(["payment", this.transId, this.secret, "failed"])
  }

  onAcceptButtonClicked() {
    this.router.navigate(["payment", this.transId, this.secret, "success"])
  }

  getCoverImage(response: any) : ImageVM{
    let a = this.mediaService.selectImageFromResponse(response);
    console.log((<any>this.data.university).data.attributes);
    return a;
  }
  getLogoImage(response: any) : ImageVM {
    return this.mediaService.selectImageFromResponse(response);
  }

  getStartDate(date: string) {
    return new Date(date);
  }

  getEndDate(date: string, length: number){
    let current = new Date(date);
    current.setMonth(current.getMonth() + length);
    return current;
  }
}
