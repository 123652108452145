<div class="footer">
  <div class="container">
    <ul>
      <li><a routerLink="/terms-of-conditions">{{ "Terms and conditions" | trans: 'footer.terms_and_conditions' }}</a></li>
      <li><a routerLink="/privacy-policy">{{ "Privacy policy" | trans: 'footer.privacy_policy' }}</a></li>
      <li *ngIf="gds.contactEmail"><a href="mailto:{{gds.contactEmail}}">{{ "Contact us" | trans: 'footer.contact' }}</a></li>
    </ul>
    <div>© {{currentYear}} {{projectName}} - {{ "All Rights Reserved!" | trans: 'footer.copyright' }}</div>
    <div class="social-link-container">
      <a href="{{ '' | trans: 'footer.facebook_link' }}">
        <img src="assets/ic_social_facebook.svg">
      </a>
    </div>

  </div>
</div>
