import {Injectable} from '@angular/core';

const KEY_LOCALE = "selected-locale";
const KEY_AUTH_TOKEN = "auth-token";
const KEY_PENDING_TEST_RESULT = "pending-test-result";
const DEFAULT_LOCALE = "hu";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  get locale(): string {
    return this.get(KEY_LOCALE, DEFAULT_LOCALE);
  }

  set locale(locale: string) {
    this.set(KEY_LOCALE, locale);
  }

  get authToken(): string | null {
    return this.get(KEY_AUTH_TOKEN, null)
  }

  set authToken(token: string | null) {
    this.set(KEY_AUTH_TOKEN, token)
  }

  set pendingTestResult(id: number | null){
    this.set(KEY_PENDING_TEST_RESULT, id);
  }

  get pendingTestResult() : number | null {
    return this.get(KEY_PENDING_TEST_RESULT, null);
  }


  private set(key: string, value: any | null): void {
    if (value == null) {
      this.delete(key);
    } else {
      localStorage.setItem(key, value);
    }
  }

  private get(key: string, defaultValue: any): any {
    return localStorage.getItem(key) ?? defaultValue;
  }

  private delete(key: string): void {
    localStorage.removeItem(key);
  }
}
