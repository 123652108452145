import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HighSchoolSubjectVM} from "../../../../../model/viewModel/calculator.vm";

export class CpiInputChanged {
  subject: HighSchoolSubjectVM;
  value: number;
  isAdvanced: boolean;
}

@Component({
  selector: 'app-cpi-input-row',
  templateUrl: './cpi-input-row.component.html',
  styleUrls: ['./cpi-input-row.component.scss']
})
export class CpiInputRowComponent implements OnInit {
  @Input() maxLength: number;
  @Input() subjects: HighSchoolSubjectVM[];
  @Input() label: string;
  @Input() levelDecoration: boolean;
  @Output() output = new EventEmitter<CpiInputChanged>();
  private model: CpiInputChanged = <CpiInputChanged>{
    isAdvanced: false,
  };

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.subjects);
    if (this.subjects.length == 1) {
      this.model.subject = this.subjects[0];
    }
  }

  onLevelChanged(event) {
    this.model.isAdvanced = event.target.checked;
    this.emitModelChange();
  }

  onValueChanged(event) {
    this.model.value = parseInt(event.target.value);
    this.emitModelChange();
  }

  onSubjectChanged(event) {
    let subjectId = event.target.value;
    this.subjects.find(item => item)
    this.model.subject = this.subjects.find(item => item.id == subjectId);
    this.emitModelChange();
  }

  private emitModelChange(){
    if(this.model.subject && this.model.value){
      this.output.emit(this.model);
    }
  }

}
