import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogService} from "../../../service/dialog.service";
import {AuthService} from "../../../service/auth.service";
import {Router} from "@angular/router";
import {GlobalDataService} from "../../../service/global-data.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  loginForm = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl("", [
      Validators.required
    ])
  })

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password")
  }

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private router: Router,
    public gds: GlobalDataService
  ) {

  }

  ngOnInit(): void {
  }

  onSSOLoginClicked() {
    //TODO: Not implemented yet
  }

  onLoginFormSubmitted() {
    this.submitted = true;
    this.loginForm.markAllAsTouched()
    if (this.loginForm.invalid) {
      return;
    }
    let model = this.loginForm.value;
    this.authService.login(model.email!, model.password!).subscribe({
      next: (result) => {
        if (result.target == "test-result") {
          this.router.navigate(["test-result", result.targetId])
        } else {
          this.router.navigate(["/"])
        }
      },
      error: () => {
        this.dialogService.show({
          title: this.gds.translations["login"]["submit_error_invalid_credentials_title"],
          content: this.gds.translations["login"]["submit_error_invalid_credentials_description"],
          buttons: {
            positive: {
              label: this.gds.translations["login"]["submit_error_invalid_credentials_button"]
            }
          },
          type: "error"
        });
      }
    });

  }
}
