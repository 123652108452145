import {Component, Input, OnInit} from '@angular/core';
import {MainCalculatorVM} from "../../../../model/viewModel/main.vm";

@Component({
  selector: 'app-main-calculator',
  templateUrl: './main-calculator.component.html',
  styleUrls: ['./main-calculator.component.scss']
})
export class MainCalculatorComponent implements OnInit {
  @Input() data!: MainCalculatorVM

  constructor() { }

  ngOnInit(): void {
  }

}
