import {Component, OnInit} from '@angular/core';
import {PlanVM} from "../../../../model/viewModel/plan.vm";
import {PlansService} from "../../../../service/plans.service";
import {OrderService} from "../../../../service/order.service";
import {Router} from "@angular/router";

export type PlanVMContainer = {
  plan: PlanVM;
  isHighlighted: boolean;
};

@Component({
  selector: 'app-main-plans',
  templateUrl: './main-plans.component.html',
  styleUrls: ['./main-plans.component.scss']
})
export class MainPlansComponent implements OnInit {
  plans?: PlanVMContainer[] = undefined

  constructor(
    private planService: PlansService,
    private orderService: OrderService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.planService
      .getPlans()
      .subscribe(data => {
        let plans = data
          .sort((left, right) => left.price - right.price)
          .map(item => {
            return <PlanVMContainer>{
              plan: item,
              isHighlighted: false
            };
          });
        if (plans.length >= 3) {
          let centerIndex = parseInt(Math.floor(plans.length / 2).toString());
          let p = plans[centerIndex];
          plans[plans.length - 1].isHighlighted = true
          plans[centerIndex] = plans[plans.length - 1];
          plans[plans.length - 1] = p
        }

        this.plans = plans;
      });
  }

  onPlanClicked(plan: PlanVM) {
    this.orderService.createPlanOrder(plan.id).subscribe(transId => {
      this.router.navigate(["payment", transId]);
    });
    return false;
  }
}
