import {Component, OnInit} from '@angular/core';
import {PersonalityTestService} from "../../../../service/personality-test.service";
import {TestResultListDTO, TestResultRawDTO} from "../../../../model/http/test-result.dto";
import {Router} from "@angular/router";
import {CourseTagDTO} from "../../../../model/http/test.dto";
import {MediaMapperService} from "../../../../mapper/media-mapper.service";

@Component({
  selector: 'app-user-profile-test-result',
  templateUrl: './user-profile-test-result.component.html',
  styleUrls: ['./user-profile-test-result.component.scss']
})
export class UserProfileTestResultComponent implements OnInit {
  isLoading: boolean = true;
  data: TestResultListDTO;
  constructor(
    private testService: PersonalityTestService,
    private mediaMapperService: MediaMapperService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.testService.getTestResults().subscribe({
      next: (data) => {
        this.isLoading = false;
        this.data = data.filter(item => item?.best_course_tag?.icon && item?.best_course_tag?.name && item?.best_course_discipline?.name);
        console.log(data);
      }
    });
  }

  onTestResultClicked(tr: TestResultRawDTO) {
    this.router.navigate(["test-result", tr.id]);
  }

  getIconUrl(courseTag: CourseTagDTO) : string {
    return this.mediaMapperService.mapToImage(courseTag.icon).url;
  }
}
