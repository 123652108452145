import {Component, Input, OnInit} from '@angular/core';
import {MainHeaderVM} from "../../../../model/viewModel/main.vm";
import {GlobalDataService} from "../../../../service/global-data.service";
import {AuthService} from "../../../../service/auth.service";

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  @Input() data!: MainHeaderVM;

  constructor() {
  }

  ngOnInit(): void {

  }

}
