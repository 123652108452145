import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {ServicesService} from "../../../service/services.service";
import {ActivatedRoute, Router} from "@angular/router";
import {first, zip, map, mergeMap, toArray, combineLatest} from "rxjs";
import {StorageService} from "../../../service/storage.service";
import {OrderService} from "../../../service/order.service";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  isSuccess: boolean = false;
  submitted: boolean = false;
  paymentForm = new FormGroup({
    firstName: new FormControl("", [
      Validators.required,
    ]),
    lastName: new FormControl("", [
      Validators.required
    ]),
    country: new FormControl("", [
      Validators.required
    ]),
    city: new FormControl("", [
      Validators.required
    ]),
    zipCode: new FormControl("", [
      Validators.required,
    ]),
    street: new FormControl("", [
      Validators.required
    ]),
    paymentMethod: new FormControl("", [
      Validators.required
    ]),
    // tocAccept: new FormControl("", [
    //   Validators.requiredTrue
    // ])
  })

  isInvalid(name: string): boolean {
    let field = this.paymentForm.get(name);
    return !!(this.submitted && field?.invalid && (field?.dirty || field?.touched))
  }

  isRequired(name: string): boolean {
    let field = this.paymentForm.get(name);
    return !!(field?.errors?.['required']);
  }

  get firstName(): AbstractControl {
    return this.paymentForm.get("firstName")
  }

  get lastName(): AbstractControl {
    return this.paymentForm.get("lastName")
  }

  get country(): AbstractControl {
    return this.paymentForm.get("country")
  }

  get city(): AbstractControl {
    return this.paymentForm.get("city")
  }

  get zipCode(): AbstractControl {
    return this.paymentForm.get("zipCode")
  }

  get street(): AbstractControl {
    return this.paymentForm.get("street")
  }

  get paymentMethod(): AbstractControl {
    return this.paymentForm.get("paymentMethod")
  }

  get tocAccept(): AbstractControl {
    return this.paymentMethod.get("tocAccept")
  }

  constructor(
    private servicesService: ServicesService,
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    combineLatest(
      this.activatedRoute.queryParamMap,
      this.activatedRoute.params
    ).subscribe(result => {
      console.log("Result: ", result);
      let [queryMap, params] = result;
      let payerId = queryMap.get("PayerID");
      let paymentId = queryMap.get("paymentId");
      console.log("PayerId: ", payerId);
      if (params["result"] == 'success' && payerId && paymentId) {
        this.orderService.finalizePayPalOrder(<any>params["transId"], payerId, paymentId).subscribe((response) => {
          if (response.target == "test-result" && response.targetId) {
            this.router.navigate(["test-result", response.targetId])
          } else {
            this.isSuccess = true;
          }
        });
      } else if (params["result"] == 'cancelled') {
        alert("Payment failed");
      }
    });
  }

  onSelectPaymentMethodClicked(method: string) {
    this.paymentMethod.setValue(method);
  }


  onPaymentFormSubmitted() {
    console.log("data: ", this.paymentForm.value);
    console.log("data: ", this.paymentForm.invalid);
    if (this.paymentForm.invalid) {
      //Invalid form...
      return;
    }
    if (this.paymentMethod.value != "paypal") {
      alert("This payment method is not supported yet");
      return;
    }
    this.activatedRoute.params
      .pipe(
        first(),
        mergeMap(params => {
          let orderId = params["transId"];
          return this.orderService.updatePayPalOrder(
            orderId,
            this.firstName.value,
            this.lastName.value,
            this.country.value,
            this.city.value,
            this.zipCode.value,
            this.street.value
          )
        })
      )
      .subscribe({
        next: (redirectUrl) => {
          window.location.href = redirectUrl;
        }
      });
  }
}
