<div class="card result-card p-2 mb-4">
  <div class="card-body">
    <h3>{{ "Estimated points" | trans: 'calculator.estimation_title' }}</h3>
    <div class="content-row row" *ngIf="calculatedGradPoints || calculatedStudyPoints">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <span class="fw-bold">{{ "Preferred calculation method:" | trans: 'calculator.estimation_pref_calc_method_title' }} </span>{{ getPreferredCalculationMethod() }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-left">
            {{ "Points from studies" | trans: 'calculator.estimation_points_from_studies' }}
          </div>
          <div class="col-6 text-end" *ngIf="calculatedStudyPoints">
            {{ calculatedStudyPoints }}/200
          </div>
          <div class="col-6 text-end" *ngIf="!calculatedStudyPoints">
            -/200
          </div>

        </div>
        <div class="row">
          <div class="col-6 text-left">
            {{ "Required graduation points" | trans: 'calculator.estimation_error_grad_req' }}
          </div>
          <div class="col-6 text-end">
            {{ calculatedGradPoints }}/200
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-left">
            {{ "Institutional points" | trans: 'calculator.estimation_points_from_inst' }}
          </div>
          <div class="col-6 text-end">
            {{ calculatedInstitutePoints }}/100
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="estimated-points-container align-middle d-inline text-center">
          <p class="m-0 p-0">{{ "Your estimated points:" | trans: 'calculator.estimation_points_sum' }}</p>
          <p class="display-6 fw-bold m-0 p-0">{{ calculatedSummaryPoints }}</p>
        </div>
      </div>

    </div>
    <div class="content-row" *ngIf="!calculatedGradPoints && !calculatedStudyPoints">
      <h3>{{ "You are not applicable" | trans: 'calculator.estimation_error_title' }}</h3>
      <p>{{ "Message" | trans: 'calculator.estimation_error_message' }}</p>
      <h4>{{ "Study subject requirement:" | trans: 'calculator.estimation_error_study_req' }}</h4>
      <ul>
        <li *ngFor="let req of model.courseSelectionResult.study_last_year_req">{{ req.name }}</li>
      </ul>
      <h4>{{ "Graduation subject requirement:" | trans: 'calculator.estimation_error_grad_req' }}</h4>
      <ul>
        <li *ngFor="let req of model.courseSelectionResult.study_grad_req">{{ req.name }}</li>
      </ul>
    </div>
  </div>
</div>
