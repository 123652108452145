<app-menu currentMenu="test"></app-menu>
<div class="page page-course-details">
  <app-loading *ngIf="!isLoaded"></app-loading>
  <div class="course-details container" *ngIf="isLoaded">
    <div class="cover-container">
      <img *ngIf="getCoverImage(data.university.data.attributes.cover) != null" src="{{ getCoverImage(data.university.data.attributes.cover)?.url }}" class="cover">
      <div *ngIf="getCoverImage(data.university.data.attributes.cover) == null" class="no-cover-container"></div>
      <div class="name-container">
        <div class="name">
          <h3>{{ data.university.data.attributes.name }}</h3>
          <small>{{ data.university.data.attributes.location}}</small>
        </div>
      </div>
    </div>
    <div class="data-container">
      <div class="info-cards">
        <div class="card">
          <h5>{{ "Disciplines" | trans: 'coursedetails.title_disciplines' }}</h5>
          <p *ngFor="let disc of data.course_disciplines.data">{{ disc.attributes.name }}</p>
        </div>
        <div class="card">
          <h5>{{ "Course periods" | trans: 'coursedetails.title_course_periods' }}</h5>
          <div class="card-table" *ngIf="data.start_date_1">
            <div>{{ getStartDate(data.start_date_1) | date: 'MMMM yyyy' }}</div>
            <div>-></div>
            <div>{{ getEndDate(data.start_date_1, data.full_duration) | date: 'MMMM yyyy' }}</div>
          </div>
          <div class="card-table" *ngIf="data.start_date_2">
            <div>{{ getStartDate(data.start_date_2) | date: 'MMMM yyyy' }}</div>
            <div>-></div>
            <div>{{ getEndDate(data.start_date_2, data.full_duration) | date: 'MMMM yyyy' }}</div>
          </div>
          <div class="card-table" *ngIf="data.start_date_3">
            <div>{{ getStartDate(data.start_date_3) | date: 'MMMM yyyy' }}</div>
            <div>-></div>
            <div>{{ getEndDate(data.start_date_3, data.full_duration) | date: 'MMMM yyyy' }}</div>
          </div>
        </div>
        <div class="card">
          <h5>{{ "Language" | trans: 'coursedetails.title_language' }}</h5>
          <div class="single-value">{{ data.language }}</div>
          <div class="point-container">
            <div class="point" *ngIf="data.point_ielts"><b>IELTS</b> {{ data.point_ielts }}</div>
            <div class="point" *ngIf="data.point_pte_acedemic_key_info"><b>PTE</b> {{ data.point_pte_acedemic_key_info }}</div>
            <div class="point" *ngIf="data.point_toefl"><b>TOEFL</b> {{ data.point_toefl }}</div>
          </div>
        </div>
        <div class="card">
          <h5>{{ "Delivered" | trans: 'coursedetails.title_delivered' }}</h5>
          <div class="single-value" *ngIf="data.delivered == 'on_campus'">{{ "On Campus" | trans: 'coursedetails.delivered_local' }}</div>
          <div class="single-value" *ngIf="data.delivered == 'online'">{{ "Online" | trans: 'coursedetails.delivered_online' }}</div>
        </div>

        <div class="card">
          <h5>{{ "Price" | trans: 'coursedetails.title_price' }}</h5>
          <div class="currency">
            <div class="amount">{{ data.fee }} EUR</div>
            <div class="period">/{{ data.fee_type }}</div>
          </div>
        </div>
      </div>
      <div class="details">
        <h2>{{ data.name }}</h2>
        <h4>{{ "Descriptions" | trans: 'coursedetails.title_descriptions' }}</h4>
        <p>{{ data.description }}</p>

      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
