<div class="card w-50 mx-auto">
  <div class="card-header">{{ "Password change" | trans: "usersettings.password_change_header" }}</div>
  <div class="card-body">
    <form [formGroup]="passwordChangeForm" (ngSubmit)="onPasswordChangeSubmitted()">

      <div class="form-group mb-3">
        <label>{{ "Current password" | trans: "usersettings.password_change_input_current" }}</label>
        <input formControlName="currentPassword" type="password" class="form-control"  placeholder="{{ 'Current password' | trans: 'usersettings.password_change_input_current' }}">
        <small class="form-text text-danger" *ngIf="submitted && invalidPasswordError">{{ "Invalid password" | trans: 'usersettings.password_change_error_password_invalid' }}</small>
      </div>
      <div class="form-group mb-3">
        <label>{{ "New password" | trans: "usersettings.password_change_input_first" }}</label>
        <input type="password" class="form-control" formControlName="newPassword1" placeholder="{{ 'New password' | trans: 'usersettings.password_change_input_first' }}">
        <div class="error-container" *ngIf="submitted && newPassword1?.invalid && (newPassword1?.dirty || newPassword1?.touched)">
          <small class="d-block form-text text-danger" *ngIf="newPassword1?.errors?.['required']">{{ "Field required" | trans: 'usersettings.password_change_error_required' }}</small>
        </div>
        <small class="d-block form-text text-danger" *ngIf="submitted && newPassword1.value != newPassword2.value">{{ "New passwords are not the same" }}</small>
      </div>
      <div class="form-group mb-4">
        <label>{{ "New password again" | trans: "usersettings.password_change_input_second" }}</label>
        <input type="password" class="form-control" formControlName="newPassword2" placeholder="{{ 'New password' | trans: 'usersettings.password_change_input_second' }}">
        <div class="error-container" *ngIf="submitted && newPassword2?.invalid && (newPassword2?.dirty || newPassword2?.touched)">
          <small class="d-block form-text text-danger" *ngIf="newPassword2?.errors?.['required']">{{ "Field required" | trans: 'usersettings.password_change_error_required' }}</small>
        </div>
        <small class="d-block form-text text-danger" *ngIf="submitted && newPassword1.value != newPassword2.value">{{ "New passwords are not the same" | trans: 'usersettings.password_change_error_password_not_the_same' }}</small>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-primary" [disabled]="isLoading">{{ "Submit" | trans: "usersettings.password_change_submit" }}</button>
      </div>
    </form>
  </div>
</div>
