<app-menu currentMenu="test"></app-menu>
<div class="page page-test">
  <app-loading *ngIf="!isLoaded"></app-loading>
  <div class="test-container">
    <div class="test-card">
      <div class="test-item first-page" *ngIf="!isEnded && !currentTestItem">
        <img src="assets/bg_test_first_page.png">
        <h2>{{ "Take a test today" | trans: 'test.first_page_title' }}</h2>
        <p>{{ "This comprehensive test evaluates individuals' abilities and interests in a range of activities related to plants and other areas. By presenting a series of questions, users are prompted to assess their perceived capability or interest level for each activity, providing valuable insights for suggesting suitable universities. The test enables individuals to discover educational institutions aligned with their passions and offering relevant programs, empowering them to make informed decisions about their academic journey and pursue their interests confidently." | trans: 'test.first_page_description' }}</p>
        <a href="#" (click)="onStartTestButtonClicked()">{{ "Start the test" | trans: 'test.first_page_button' }}</a>
      </div>
      <div class="test-item test-counter"
           *ngIf="currentTestItem && currentTestItem.behaviour[0].__component == 'test.test-behaviour-course-tag-counter'">
        <img *ngIf="getImage(currentTestItem?.illustration)" src="{{ getImage(currentTestItem?.illustration).url }}">
        <h2>{{ currentTestItem.question }}</h2>
        <div class="rate-container">
          <div class="rate-button" (click)="onRateButtonClicked(1)"><img src="assets/ic_rate_1.png"></div>
          <div class="rate-button" (click)="onRateButtonClicked(2)"><img src="assets/ic_rate_2.png"></div>
          <div class="rate-button" (click)="onRateButtonClicked(3)"><img src="assets/ic_rate_3.png"></div>
          <div class="rate-button" (click)="onRateButtonClicked(4)"><img src="assets/ic_rate_4.png"></div>
          <div class="rate-button" (click)="onRateButtonClicked(5)"><img src="assets/ic_rate_5.png"></div>
        </div>
      </div>
      <div class="test-item test-location-selector"
           *ngIf="currentTestItem && currentTestItem.behaviour[0].__component == 'test.test-behaviour-location-selector'">
        <img *ngIf="getImage(currentTestItem?.illustration)" src="{{ getImage(currentTestItem?.illustration).url }}">
        <h2>{{currentTestItem.question}}</h2>

        <div class="location-container">
          <a href="#" *ngFor="let location of locations" (click)="onLocationButtonClicked(location)">{{location.name}}</a>
        </div>

      </div>
      <div class="test-item test-locale-selector"
           *ngIf="currentTestItem && currentTestItem.behaviour[0].__component == 'test.language'">
        <img *ngIf="getImage(currentTestItem?.illustration)" src="{{ getImage(currentTestItem?.illustration).url }}">
        <h2>{{currentTestItem.question}}</h2>

        <div class="locale-container">
          <a href="#" *ngFor="let locale of locales" (click)="onLanguageButtonClicked(locale)">{{locale.name}}</a>
        </div>

      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
