import {Component, Input, OnInit} from '@angular/core';
import {MainAboutVM} from "../../../../model/viewModel/main.vm";

@Component({
  selector: 'app-main-about',
  templateUrl: './main-about.component.html',
  styleUrls: ['./main-about.component.scss']
})
export class MainAboutComponent implements OnInit {
  @Input() data!: MainAboutVM

  constructor() {
  }

  ngOnInit(): void {
  }

}
