<app-menu></app-menu>
<div class="page page-user-settings">
  <app-loading *ngIf="isLoading"></app-loading>

  <div class="user-settings-container" *ngIf="!isLoading">
    <div class="container p-4">
      <app-user-settings-password></app-user-settings-password>
    </div>
  </div>
</div>
<app-footer></app-footer>
